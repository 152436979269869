import Vue from 'vue'
import ElementUI from 'element-ui'
import Swiper from 'swiper'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from '@/utils/i18n/i18n'
import http from '@/utils/request/http'
import '@/utils/common/rem'
import storage from '@/utils/common/storage'
import VueAwesomeSwiper from 'vue-awesome-swiper';

import "swiper/css/swiper.css";
import 'element-ui/lib/theme-chalk/index.css';
// 基础图标
import "@/assets/icons/font_base/iconfont.css";
import '@/assets/icons/font_base/iconfont';
// 银行列表图标
import "@/assets/icons/font_bank_list/iconfont.css";
import '@/assets/icons/font_bank_list/iconfont';
// 支付相关图标
import "@/assets/icons/font_pay/iconfont.css";
import '@/assets/icons/font_pay/iconfont';

import GlobalCss from '@/assets/theme/index.less'

import Icon from '@/components/global/Icon';

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.prototype.$swiper = Swiper;
Vue.prototype.$http = http;
Vue.prototype.GlobalCSS = GlobalCss
Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper);
Vue.use(storage)
Vue.component('Icon', Icon)

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
