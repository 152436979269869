<template>
  <div id="app">
    <router-view/>
    <div class='scroll' :class='{show:isActive}'>
      <div id='toTop' @click='toTop(40)'>
        <div class='top-icon'><i class='el-icon-arrow-up'></i></div>
        <div class='top-tip'>TOP</div>
      </div>
    </div>
  </div>
</template>
<script>

import {setTheme} from "@/assets/theme";
import {getTheme} from "@/utils/common";
import { setMyInfo, getMyInfo, } from "@/utils/common/user";
import { getToken,removeToken } from "@/utils/common";
import { myInfo } from "@/api/Mine";

export default {
  name: "App",
  components: {},
  data() {
    return {
      isActive: false,
      num:'',
      type:'',
      dropdownList:[]
    }
  },
  mounted() {
    // 设置主题
    if (getTheme()) {
      setTheme(getTheme())
    } else {
      setTheme("dark");
    }

    window.addEventListener('scroll', this.handleScroll)
    if(this.getToken()){
      myInfo().then((res) => {
        this.$store.state.loginname = res.data.name;
        this.$store.state.userImage = res.data.image;
        this.$store.state.introduce = res.data.introduce
        // this.$store.state.isProve = res.data.is_prove
        this.$store.state.identity = res.data.identity
        this.$store.state.countryId = res.data.countryId
        console.log('存入111')
        this.setMyInfo(res.data)
        // console.log(this.getMyInfo(),'getMyInfo111111')
        // console.log(this.$store.state.loginname,'myInfo')
      });
    }

    // 解决刷新页面vuex数据丢失问题
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(
          Object.assign({}, JSON.parse(sessionStorage.getItem('store')))
      )
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  methods: {
	  setMyInfo,
	  getMyInfo,
	  getToken,
    handleScroll(e) {
      if (document.documentElement.scrollTop > 50) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    toTop() {
      // 参数step表示时间间隔的幅度大小，以此来控制速度
      // 当回到页面顶部的时候需要将定时器清除
      let time
      document.documentElement.scrollTop -= 40
      if (document.documentElement.scrollTop > 0) {
        time = setTimeout(() => this.toTop(40), 15)
      } else {
        clearTimeout(time)
      }
    }
  }
};
</script>

<style lang="less">
@import "./assets/css/reset.css";
@import "./assets/css/global.less";

body {
  background-color: @baseBackgroundColor;
}

::v-deep .el-cascader-panel {
  background-color: @uploadInputBackgroundColor !important;
}

.scroll {
  position: fixed;
  right: 2em;
  // bottom: 4rem;
  bottom: 6rem;
  cursor: pointer;
  display: none;
  z-index: 100;
}

.scroll > div {
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, .1);
  border: 1px rgba(0, 0, 0, 0.1) solid;
  transition: all .3s;
}


.top-icon {
  font-size: 18px;
  color: #3399FC;
  font-weight: 600;
}

.top-tip {
  font-size: 16px;
  font-weight: 600;
  color: #3399FC;
}

.scroll > div:hover {
  background-color: rgba(255, 255, 255, .1);
}

.show {
  display: block;
}
</style>
