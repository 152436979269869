//设计稿宽度1920px
(function () {
    function setRootFontSize() {
        let rem, rootWidth
        let rootHtml = document.documentElement
        //限制展现页面的最小宽度
        rootWidth = rootHtml.clientWidth < 1920 ? 1920 : rootHtml.clientWidth
        rem = rootWidth / 1920 * 18
        // 动态写入样式
        rootHtml.style.fontSize = `${rem}px`
    }

    setRootFontSize()
    window.addEventListener('resize', setRootFontSize, false)
})()