import cnLocale from 'element-ui/lib/locale/lang/zh-CN'

const cn = {
	...cnLocale,
	// 推荐
	recommend: {
		recommendhome: '推荐',
		musician: '音乐人',
		works: '作品',
		fans: '粉丝',
		followed: '已关注',
		noFollow: '关注TA',
		find: '找到',
		focusSuccess: '关注成功',
		finishedMusic: '成品音乐',
		demoSea: 'DEMO (海外)',
		demoLocal: 'DEMO (内地)',
		enterMusicLibrary: '进入音乐库',
		WorkCollaboration: '作品协作区',
		loginSucceeded: '登录成功',
		loginSucceededContent: '亲爱的用户，您已登录成功，7KEY MUSIC将送您十分钟的试听权限，因平台实行音乐人原创保护机制，故试用权限到期后，您可以选择企业认证、音乐人认证或是进行充值，否则平台有权对您的账号进行封禁处理，望您知悉~',
		cancel: '取消',
		confirm: '确认',
		canceldel: "已取消删除",
		popupJurisdiction: '提示',
		popupJurisdictionContent: '亲爱的用户，您的一个月的试听权限已到期，因平台实行音乐人原创保护机制，请点击下方链接前往企业认证、音乐人认证或者进行充值。否则平台有权对您的账号进行封禁处理，望您知悉~',
		enterpriseCertification: '点击立即前往企业认证',
		musicianCertification: '点击立即前往音乐人认证',
		accountRecharging: '点击立即前往进行账号充值',
		payImmediately: '立即支付',
		completePayment: '完成支付',
		transferPopupTitle: '支付提示',
		transferPopupText1: '系统检测到您未支付成功',
		transferPopupText2: '您可能未支付/转账成功',
		transferPopupText3: '您可能支付成功，但没有上传支付凭证',
		transferPopupText4: '您上传的并不是支付凭证',
		singer: '演唱者',
		more: '更多',
		buyNow: '立即购买',
		authorizedTerritory: '授权地域',
		authorizedType: '授权类型',
		authorizedyear: "授权年限",
		selectcouppon: "选择优惠券",
		lockTime: '锁定时间',
		day: '天',
		orderSn: '订单编号',
		all: '全部',
		please: '请在',
		orderCloseTitle: '内完成支付，否则订单将关闭',
		amountPayable: '应付金额',
		paymentMethod: '支持的付款方式',
		weChatPayment: '微信支付',
		alipayPayment: '支付宝支付',
		corporateTransfer: '对公转账',
		corporateTransferInfo: '对公转账信息',
		corporateName: '名称',
		bankDeposit: '开户银行',
		bankAccount: '银行账号',
		paymentVoucher: '上传支付凭证',
		clickUpload: '点击上传',
		format: '格式',
		buysubscribe: '购买订阅包',
		subscribetime: "订阅时间",
		authorizedTerritoryText: '大陆',
		authorizationTypeText: '灌录权（灌录权、传播权、复制权）',
		copysuccess: "复制歌曲成功",
		copyfaild: "复制歌曲失败",
		slowly: "慢",
		middle: "中",
		faster: "快",
		man: "满",
		payweixin: "微信",
		payapliy: "支付宝",
		uploadBeat: "上传Beat",
		copyCodeFailed: '复制邀请码失败',
		collection: '收藏',
		fenxiang: '分享',
		suoding: '锁定',
		cancelCollectin: "取消收藏",
		xiazai: "下载",
		// copyCodeSuccess: '复制邀请码成功'
	},
	// 定制
	Customization: {
		selectMusician: '选择音乐人',
		addMusician: '添加音乐人',
		basicInformation: '基础信息介绍',
		songName: '歌曲名称',
		proposedSinger: '拟定演唱者',
		introductionSingers: '歌手介绍',
		projectIntroduction: '项目介绍',
		songUsage: '歌曲用途',
		demandBackground: '需求背景/目的',
		targetAudience: '目标受众',
		lyrics: '歌词内容/主题/核心表达',
		keyWordsSongs: '歌曲关键词',
		songDirection: '歌曲方向',
		qufengPositioning: '曲风定位',
		referenceSong: '参考歌曲(Ref)',
		releaseTime: '收歌截止时间和发行时间',
		release: '发布',
		selectDate: '选择日期',
		selectTime: '选择时间',
		submitApplication: '提交申请',
		addtotal: "共有",
		mingmusicial: "名音乐人",
		pleaseinputproposedsinger: "请输入拟定演唱者",
		pleaseinputintruducesinger: "请输入歌手介绍",
		pleaseinputintruduceproject: "请输入项目介绍",
		longnotmorefive: "长度不能超过500 个字符",
		pleaseinputkeywords: "请输入歌曲关键词",
		pleaseinputpurposesong: "请输入歌曲用途",
		pleaseinputbackground: "请输入需求背景/目的",
		pleaseinputtargetaudience: "请输入目标受众",
		pleaseinputtheme: "请输入歌词内容/主题/核心表达",
		longnotmoretwo: "长度不能超过200 个字符",
		pleaseinputposition: "请输入曲风定位",
		pleaseinputref: "请输入参考歌曲(Ref)",
		pleasedeadlinetime: "请选择截至日期",
		pleasereleasetime: "请选择发行时间",
		hasaddmusicianlist: "已添加至音乐人列表"
	},
	// 音乐库
	musiclibraryObj: {
		shop: '立即购买',
		song: '认领伴奏',
		claim: '编曲认领'
	},
	haveexpired: '已过期',
	notstart: '未开始',
	underway: '进行中',
	songcollection: '歌曲征集',
	discountsection: '折扣专区',
	messagecenter: '消息中心',
	musiclibrary: '音乐库',
	musicAI: 'AI作曲',
	mymusicAI: 'AI音频',
	musicaigequmiaoshu: '歌曲描述',
	musicaigequmiaoshutt: '描述你想要的音乐风格和主题（例如“关于假日）。使用流派和氛围，而不是特定的艺术家和歌曲。',
	musicaigequgeci: '随机生成歌词，自己写，或者从Al那里得到一些帮助。使用两首诗（8行）获得最佳效果。',
	musicaigequfenggei: '描述你想要的音乐风格 例如：流行、摇滚、史诗。我们的模型无法识别具体的艺人名称，但能理解音乐类型和氛围。',
	musicaigequtitle: '为您的歌曲命名',
	musicaigequgegeci: '歌词',
	musicaigequgemakegeci: '生成歌词',
	musicaigequstyle: '音乐风格',
	musicaigequgett: '歌曲名称',
	musicaigequgeciinput: '输入你的歌词',
	musicaigequstyleinput: '输入音乐风格（请用英文）',
	musicaigequgecittinput: '输入音乐名称',
	musicaigequinput: '请输入您的歌曲描述，比如：写一首关于爱情的日本流行歌曲',
	musicaichunyinyue: '纯音乐',
	musicaichunyinyuett: '没有歌词的纯音乐',
	musicaibuttonchuangzuo: '开 始 创 作',
	musicaimiaoshu: '描 述',
	musicailistkong: '暂无作品',
	musicaizuopin: '作品',
	musicaixiazaiyinyue: '下载',
	musicaichakangeci: '歌词',
	musicaiwodejifen: '我的积分',
	musicaixiaohaojifen: '每次创作消耗10积分',
	activityarea: '活动专区',
	songcustomization: '歌曲定制',
	finishedmusic: '成品音乐',
	searchprompt: '搜索的歌曲名称、编号',
	workupload: '作品上传',
	overseas: '海外',
	hinterland: '内地',
	songName: '歌曲名称',
	producer: '制作人',
	singer: '歌手',
	Style: '风格',
	instruments: '乐器',
	speed: '速度',
	filtrate: '筛选',
	writWords: '作词',
	compose: '作词/作曲/编曲',
	arrangement: '作词/作曲',
	arrangementbeat: '作曲',
	lyricsText: "歌词",
	singSinger: '适合歌手',
	pleaseSelectSuitSinger: '请选择该歌曲适合的歌手',
	pleaseSelectSongStyle: '请选择该歌曲的所属风格',
	pleaseSelectSongUseInstrument: '请选择该歌曲使用的乐器',
	pleaseSelectSongMood: '请选择该歌曲的情绪',
	songStyle: '歌曲风格',
	musicalInstrument: '乐器',
	claimwork: '我认领的作品',
	audiofile: '音频文件',
	uploadlyrics: '上传作词',
	collaborate: '是否协作',
	applylyrics: '申请填词',
	guidesing: '申请导唱',
	emotion: '情绪',
	readAndAgree: '阅读并同意',
	musicianOccupancyAgreement: '音乐人入驻协议',
	clickUpload: '点击上传',
	publishWork: '发布作品',
	uploadTip: '支持jpg/png格式，尺寸196*196px，最大不超过10M',
	vCodeLoginText: '短信登录',
	passwordLoginText: '密码登录',
	mobile: '手机号',
	inputmobile: '请输入手机号',
	placeholder: '请输入',
	vCodeText: '验证码',
	forgotPassword: '忘记密码',
	signUpNow: '立即注册',
	testGetCode: '获取验证码',
	tryAgainInSeconds: '秒后重试',
	email: '邮箱',
	password: '密码',
	login: '登录',
	userName: '用户名',
	invitationCode: '邀请码',
	register: '注册',
	agree: '同意',
	userAgreement: '《用户协议》',
	privacyPolicy: '《隐私政策》',
	confirm: '确认',
	alreadyHaveAnAccountToRegister: '已有账号，立即登录',
	thePasswordEnteredTwiceDoesNotMatch: '两次输入密码不一致!',
	theMailboxFormatIsIncorrect: '邮箱格式错误',
	agreeToTheAgreement: '请阅读并同意协议后在注册!',
	Collaboration: {
		toobig: "文件太大了",
		rightAr: "请上传正确格式的AR文件",
		rightmr: "请上传正确格式的MR文件",
		rightmulti: "请上传正确格式的Multi文件",
		rightAudio: "请上传正确格式的Audio文件",
		rightLyrics: "请上传正确格式的作词文件",
		rightcover: "请上传正确格式的封面",
		shangjiaxuzhi: "上架须知",
		xuzhi1: "上传时需保证您的作品为未经发行的原创作品；",
		xuzhi2: "作品审核将在72小时之内通过审核；",
		xuzhi3: "如您要将已审核通过的原创作品进行下架处理，请提前七日提交下架申请；",
		xuzhi4: "如在审核期间，作品交易成功，则以平台订单为主。",
		confirm: "确定",
		upsuccess_title: "发布成功",
		upsuccess_p1: "作品发布成功，等待管理员审核！",
		upsuccess_p2: "我们将在72小时之内为您审核通过，请您耐心等待。",
		collaborationsuccuess: "定制申请提交成功",
	},
	Demosea: {
		receivedSuccessfullyWhetherToDownload: "领取成功，是否下载文件?",
		tip: "提示",
		cancel: "取消",
	},
	UploadOpus: {
		noRegisteredMusician: '您还未注册为音乐人，暂时不能上传',
		twotepCertType1: "公民身份证",
		twotepCertType2: "护照",
		twotepCertType3: "港澳居民来往内地通行证",
		twotepCertType4: "台湾居民来往大陆通行证",
		twotepCertType5: "居民户口簿",
	},
	//我的页面
	mywallet: '我的钱包',
	myworks: '我的作品',
	myinterest: '我的关注',
	myfavorite: '我的收藏',
	myclaim: '我的认领',
	mypurchase: '我的购买',
	mycustom: '我的定制',
	AccountKeeper: '账户管理',
	joinorganization: '加入组织',
	businessmanagement: '企业管理',
	logout: '退出登录',
	teammanagement: '团队管理',
	accountbalance: '账户余额',
	bindbankcard: '绑定银行卡',
	Listenpermission: '试听权限',
	mycoupon: '我的优惠券',
	grossearnings: '总收入',
	Todayincome: '今日收入',
	Todayexpend: '今日支出',
	cashwithdrawalamount: '提现金额',
	revenuedetail: '收支明细',
	requestwithdrawal: '申请提现',
	havebought: '已购买',
	reserved: '已锁定',
	all: '全部',
	havepaid: '已支付',
	nonpayment: '未支付',
	authorize_title: '授权信息',
	authorize_p1: "本人（姓名）",
	authorize_p2: "身份证号",
	authorize_p3: "（以下简称：“授权人”）是本授权书附件所列录音制品词曲作者，表演者，录音制作者著作权（含表演者权）及邻接权的权利人，兹授权xxxx公司及其关联公司（以下简称“被授权方”）在中国大陆范围内的互联网及电信网络运营商所提供的通道和平台上，独家代理和运营授权人（xx）所享有的著作权和邻接权的授权作品。",
	authorize_p4: "授权范围",
	authorize_p5: "1，在互联网业务（包括但不限于作品的在线浏览，播放，下载等）及增值电信业务（包括但不限于个性化回铃音，振铃音，IVR，WAP，短信，彩信，全曲下载，手机视频，手机内置等）上独家进行开发和运营并用于互联网业务及增值电信业务以及转授权第三方进行开发和运营并用于互联网业务及增值电信业务。2，对基于授权人（xx）许可使用的作品进行翻录，修改，改编，翻译等加工制作，对制作后的产品，许可他人赋值，发行，出租，通过信息网络向公众传播。3，代理授权人（xx）在作品著作权或邻接权等权益受到侵害时向侵权单位或个人追究责任并进行诉讼。",
	authorize_p6: "xx公司及其关联公司基于推广合作作品的目的情况下，可以自行或授权合作伙伴在以下渠道使用授权人（xx）提供的相关形象和作品，",
	authorize_p7: "包括但不限于",
	authorize_p8: "通过平面，电视及广播媒体，手机终端，音像制品等途径或载体进行推广。",
	authorize_p9: "过FLASFH，MV，视频等形式进行推广。",
	authorize_p10: "通过CD，DVD，卡带 等载体，以专辑表面印刷或宣传单页等方式进行推广。",
	authorize_p11: "在网络广告，媒体广告，推广活动中使用授权内容（含授权人提供的照片，MV等）。",
	authorize_p12: "使用授权人提供的肖像图片及相关资料制作宣传页，体验卡等宣传品进行发放。",
	authorize_p13: "授权时间：自xx年x月xx日起至xx年x月xx日。授权期满前三十日内，双方将就本授权续签或变更事宜进行协商。",
	authorize_p14: "特此授权",
	authorize_p15: "授权方",
	unfinished: '未完成',
	completed: '已完成',
	canceled: '已取消',
	deadline: '收歌截止时间',
	issuedate: '收歌发行时间',
	customcoding: '定制编码',
	submitwork: '提交作品',
	generateinvitationcode: '生成邀请码',
	exclusiveinvitationcode: '专属邀请码',
	copyinvitationcode: '复制邀请码',
	invitationcodevalidityperiod: '每个邀请码有效期14天,单码单次使用',
	personaldetails: '个人资料',
	realname: '实名认证',
	enterprise: '企业认证',
	accountsecurity: '账号安全',
	stagename: '艺名(署名)',
	sex: '性别',
	personalprofile: '个人简介',
	finished: '完成',
	enterpersonalprofile: '请输入您个人简介',
	enterstagename: '请输入您的艺名(署名)',
	selectgender: "请选择性别",
	inputbriefintroduction: "请输入简介",
	man: '男',
	woman: '女',
	enterrealname: '请输入您的真实姓名',
	regions: '国家地区',
	enterregions: '请选择您的国家地区',
	documentnum: '证件号码',
	enterdocumentnum: '请输入您的证件号码',
	documentphoto: '证件照片',
	enterdocumentphoto: '身份证/护照（正面）',
	submitreport: '提交审核',
	businessname: '企业名称',
	businesscontent: '只支持中国大陆的企业认证',
	businesslogo: '企业logo',
	limits: '支持jpg/png格式，尺寸196*196px，最大不超过10M',
	businesslicense: '营业执照',
	emailaccounts: '邮箱账号',
	emailaccountstitle: '可以使用安全邮箱',
	telaccounts: '手机账号',
	telaccountstitle: '可以使用手机',
	loginpassword: '登录密码',
	loginpasswordtitle: '密码要求至少包含字母，符号或数字中的两项且长度超过8位，建议您经常修改密码，以保证帐号更加安全。',
	paypassword: '支付密码',
	paypasswordtitle: '密码要求6位数字，建议您经常修改支付密码，以保证帐号更加安全。',
	setting: '设置',
	revision: '修改',
	changes: '更改',
	Lognow: '马上登录',
	changeemail: '更改邮箱',
	newemail: '新邮箱',
	emailtitle: '请填写您的新邮箱',
	authcode: '验证码',
	haveauthcode: '获取验证码',
	authcodetitle: '请填写发送到您手机的验证码',
	changemobile: '更改手机号',
	newmobile: '新手机号',
	newmobiletitle: '填写您的新手机号码',
	changeloginpassword: '更改登录密码',
	oldpassword: '旧密码',
	oldpasswordtitle: '请填写您的旧密码',
	newpassword: '新密码',
	newpasswordtitle: '请填写您的新密码',
	subpassword: '确认密码',
	setloginpassword: '设置登录密码',
	enterpaymentpassword: '输入支付密码',
	subpaymentpassword: '确认支付密码',
	setpaypassword: '设置支付密码',
	nowpaypassword: '当前支付密码',
	newpaypassword: '新支付密码',
	subpaypassword: '确认支付密码',
	incomedetail: '收支明细',
	accounttitle: '账户名称',
	variationquantity: '变化数量',
	rechargechannel: '充值渠道',
	time: '时间',
	recharge: '充值',
	rechargemoney: '充值金额',
	inputrechargemoney: '请输入您想充值的金额',
	withdraw: '提现',
	changebankcard: '请选择银行卡',
	withdrawtitle: '请输入您想提现的金额',
	withdrawalamount: '可提现金额',
	myintegral: '积分',
	mydeintegral: '积分余额',
	exchangeintegral: '兑换积分',
	exchangemoney: '兑换金额',
	exchangemoneynono: '请输入兑换金额',
	integralDetail: "积分明细",
	integraltypetxt: '方式',
	integralshengyutxt: '剩余积分',
	paypaljine: '购买金额',
	paypalzengsongjifen: '赠送积分',
	integralshijiantxt: '时间',
	rechargeshengyu: '剩余金额',
	bankcard: '银行卡',
	bankcardnum: '银行卡号',
	inputbankcardnum: '请输入银行卡号',
	close: '关闭',
	success: '成功',
	bindsuccess: "绑定成功",
	withdrawalamountnonull: '可提现金额不能为空',
	removebind: '解除绑定',
	name: '姓名',
	inputname: '请输入姓名',
	IDnum: '身份证号',
	inputIDnum: '请输入身份证号',
	inputverificationcode: '请填写发送到您手机的验证码',
	verificationcode: '验证码',
	topupdetails: '充值明细',
	listeningcard: '试听权限卡',
	expire: '到期',
	timesrenewal: '续期次数还剩',
	frequency: '次数',
	Listeningprivilege: '试听特权',
	srenewal: '续期',
	Renewaltime: '续期时长',
	Renewaltitle: '*试听权限期内，您可畅听平台所有歌曲。',
	notlogin: "您还未登录",
	loginmore: "登录后可体验更多功能",
	promptlylogin: "立即登录",
	mannedplatform: "入驻平台",
	uploadingworks: "上传作品",
	workspurchase: "作品购买",
	numberofrenewals: "续期次数还剩",
	applyfor: "次，次数用完需再次申请",
	discountcoupon: "优惠券",
	full: "满",
	usable: "可用",
	yuan: "元",
	touse: "去使用",
	haveused: "已使用",
	unverified: "未认证",
	authenticated: "已认证",
	WeChatAccount: "微信",
	weibo: "微博",
	aboutus: "关于我们",
	useragreement: "用户协议",
	privacypolicy: "隐私政策",
	contactus: "联系我们",
	highquality: "全球高品质原创音乐DEMO库",
	dark: "暗黑主题",
	bright: "明亮主题",
	ICP: "辽ICP备2020014486号-1",
	claimedwork: "请选择你已认领的作品",
	songname:"请输入歌曲名称",
	collaboratenot:"是否协作",
	fillword: "我要填词",
	songword: "歌词",
	seesongword: "查看歌词",
	replaceword: "替换歌词",
	nextword: "下一个歌词",
	Musiccertification: "音乐人认证",
	artname: "艺名/署名",
	songartname: "歌曲发行时的署名",
	isrealname: "真实姓名",
	isrealnametitle: "请输入真实姓名,审核通过后不可更改",
	country: "国籍",
	selectcountry: "请选择国籍",
	mobiletitle: "用于官方联系您",
	desc: "个人描述",
	deschome: "将展示在您的个人主页",
	Uploadsongtitle: "您所上传的歌曲名",
	workType: "作品类型",
	seademo: "DEMO(海外)",
	songspeed: "请输入您歌曲的准确速度",
	songtone: "请选择您歌曲的准确音调",
	cover: "封面",
	moremusician: "更多音乐人",
	moreMusic: "更多音乐",
	Accompanimentclaim: "伴奏认领成功，请及时上传作品",
	Unabletoupload: "如无法上传作品，请及时取消认领",
	nomore: "没有更多了",
	Lowerarrangement: "下载编曲",
	user: "用户",
	isenterprise: "企业",
	allpreferential: "全部优惠明细",
	totalcommodityprice: "商品总价",
	coupondeduction: "优惠券抵扣",
	locksonggold: "锁定歌曲抵扣金",
	Generalpreference: "共优惠",
	total: "合计",
	preferentialdetails: "优惠明细",
	price: "价格",
	Uploadcomposition: "上传作曲",
	audited: "已审核",
	reviewed: "待审核",
	modified: "待修改",
	nopass: "未通过",
	Soldout: "已售出",
	Lockcountdown: "锁定倒计时",
	buytype: "购买类型",
	authorizedarea: "授权地区",
	authorizedtype: "授权类型",
	Disbandteam: "解散团队",
	Handoverteam: "移交团队",
	Outteam: "请出团队",
	teammembers: "团队成员",
	remove: "已下架",
	registerEmail: {
		emailinput: '请输入邮箱',
		he: '和'
	},
	cancelClaim: "取消认领",
	uploadtext: "下载",
	namecomponent: "名称：",
	tranfercomponentinfo: "沈阳梯基音乐文化传媒有限公司",
	bankaccountinfo: "建设银行沈阳南京街支行",
	pleaseclaimbeat: "请先领取编曲",
	orderhascreate: "订单已生成, 请在转账后上传凭证!",
	paysuccess: "支付成功",
	addsuccess: "添加成功",
	audioplayfaild: "音频加载失败，无法播放！",
	playhistory: "播放记录",
	confirmDel: "确定移除",
	coupponnotuse: "优惠券不可用",
	paymethodtext: "支付方式",
	submitsuccesstext: "提交成功",
	finishrecommand: "我们会尽快为您安排音乐人完成您的需求",
	
	nopermissionbrowse: "Oh~~您没有浏览这个页面的权限~",
	pageislost: "页面丢了呢！看看别的吧~",
	theservercrashed: "额哦 服务器崩溃了…",
	backhome: "返回首页",
	backpreviouspage: "返回上一页",
	countryChina: "中国",
	nonextlyrics: "暂无下一个歌词",
	realnameauthsucceeded: "您已实名认证成功",
	certifiedauthsucceeded: "您已企业认证成功",
	collectionworks: "作品收藏",
	managingmembers: "管理成员",
	inviteteammembers: "邀请团队成员",
	optiondelforever: "此操作将永久删除该订单, 是否继续?",
	renewalsucceeded: "续费成功",
	renewalfaild: "续费失败",
	createteam: "立即创建团队",
	teamname: "团队名称",
	teamnamesuit: "请输入一个适合您团队的名字",
	creater: "创建人",
	createtext: "创建",
	minsix: "长度在 6 到 20 个字符",
	minthree: "长度在 3 到 5 个字符",
	createteamtabs: "创建团队",
	reviewworks: "审核作品",
	phoneregister: "手机号注册",
	emailregister: "邮箱注册",
	resetpassword: "重置密码",
	inputactivename: "请输入活动名称",
	selectactivearea: "请选择活动区域",
	selectdata: "请选择日期",
	pleaseselecttime: "请选择时间",
	atleastselectactive: "请至少选择一个活动性质",
	selectactivereasource: "请选择活动资源",
	inputactivemethods: "请填写活动形式",
	limitselection: "当前限制选择 1 个文件，本次选择了",
	Selected: "个文件，共选择了",
	filenum: "个文件",
	creatornotnull: "制作人不能为空",
	selectclaimwork: "请选择已认领作品",
	audionotnull: "音频文件不能为空",
	lyricsnotnull: "作词文件不能为空",
	demoupnotifytitle:"错误",
	supportmp3: "支持mp3，大小不超过100M",
	supportpng: "支持jpg/png格式，大小不超过10M",
	uploadAr: "请上传AR",
	uploadfomat: "上传头像图片只能是 JPG 格式!",
	uploadsize: "上传头像图片大小不能超过 2MB!",
	uploadheadimgformat: "上传头像格式不正确",
	authenticationrightcertimg:"请上传正确格式的证件照",
	checkmusicianagreement: "请勾选音乐人入驻协议",
	mylove: "我的收藏",
	mypurchases: "我的购买",
	mycustomize: "我的定制",
	myaccount: "账户管理",
	insAccount: "Instagram账号",
	kakaotalkID: "kakaotalkID",
	headImg: '头像',
	enterpriseAgreement: "企业注册协议",
	enterpriseTitleNotNull: "企业标题不能为空",
	businesslicenseNotNull: "营业执照不能为空",
	enterpriseLogoNotNull: "企业LOGO不能为空",
	pleaseCheckEnterpriseAgree: "请勾选企业注册协议",
	readmusicianagreement: "请阅读并同意《音乐人入驻协议》",
	revenueDetail: "收入明细",
	revenueMethods: "收入渠道",
	kamiinputplace: '请输入充值的卡密',
	amichongzhi: '卡密充值',
}

export default cn