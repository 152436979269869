import qs from 'qs';
import axios from "axios";
import {Message} from 'element-ui'
import {getLang, getToken} from "@/utils/common";
import router from '@/router/index'
import { Loading } from 'element-ui';

let baseUrl;
if (process.env.VUE_APP_MODE === 'development') {
    baseUrl = '/api/';
} else {
    baseUrl = process.env.VUE_APP_API_URL;
}
const instance = axios.create({
    baseURL: baseUrl,
    headers: {
        post: {
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            // "Content-Type": "multipart/form-data;charset=UTF-8"
        }
    },
    timeout: 100000 , validateStatus: () => {
        return true;
    },
    transformRequest: [function (params) { // 处理post上传文件时参数获取不到
        if (params instanceof FormData) {
            return params;
        } else {
            return qs.stringify(params);
        }
    }],
})

// 请求拦截器
instance.interceptors.request.use(config => {
    // 发送请求前的一些处理都可以放到这里 如:token
    let token = getToken()
    let lang = getLang()
    if (token) {
        config.headers['token'] = token;
        config.headers['locale'] = lang;
    }
    return config;
}, error => {
    return Promise.reject(error);
})

// 响应拦截器
instance.interceptors.response.use(response => {
    // 接收到请求的一些数据处理都可以放到这里
    // console.log(response.status)
    if (response.status == 401) {
        // Message.warning('请登录')
        router.push({
            path: '/login/vcodelogin'
        })
    } else if (response.data.status && response.data.status != 1) {
        if (response.data.data) {
            if (response.data.data.message) {
                Message.error(response.data.data.message)
            } else {
                for (const key in response.data.data) {
                    // console.log(response.data.data[key])
                    setTimeout(() => {
                        Message.error(response.data.data[key][0])
                    }, 50)
                }
            }
        } else {
            Message.error(response.data.msg)
        }
        return Promise.reject(response);
    } else {
        return response;
    }
}, error => {
    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                error.message = '错误请求';
                break;
            case 401:
                error.message = '未授权，请重新登录';
                console.log('qudenglu')
                break;
            case 403:
                error.message = '拒绝访问';
                break;
            case 404:
                error.message = '请求错误404';
                break;
            case 405:
                error.message = '请求方法未允许';
                break;
            case 408:
                error.message = '请求超时';
                break;
            case 500:
                error.message = '服务器端出错';
                break;
            case 501:
                error.message = '网络未实现';
                break;
            case 502:
                error.message = '网络错误';
                break;
            case 503:
                error.message = '服务不可用';
                break;
            case 504:
                error.message = '网络超时';
                break;
            case 505:
                error.message = 'http版本不支持该请求';
                break;
            default:
                error.message = `连接错误${error.response.status}`;
        }
    } else {
        Loading.service({}).close();
        // 超时处理
        if (JSON.stringify(error).includes('timeout')) {
            // alert('服务器响应超时，请刷新当前页');
            Message.error('服务器响应超时，请刷新当前页')
        }
        // alert('连接服务器失败');
        Message.error('连接服务器失败')
    }
    Loading.service({}).close();
    Message.error(error.message + '- 请重试')
    // alert(error.message)
    return Promise.reject(error);
})
export default instance;
