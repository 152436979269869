import http from '@/utils/request/http'

// 我的作品
export const getMyWorks = (params = {}) => {
    return http.get('/api/myWorks', params)
}
// 关注列表
export const getfollowList = (params = {}) => {
    return http.get('/api/followList', params)
}
//我的购买
export const getpurchasedList = (params = {}) => {
    return http.get('/api/purchased', params)
}
// 添加关注
export const getfollowing = (params = {}) => {
    return http.get('/api/following', params)
}
// 取消关注
export const getFollowed = (params = {}) => {
    return http.get('/api/Followed', params)
}
// 添加喜欢
export const getLiking = (params = {}) => {
    return http.get('/api/liking', params)
}
// 取消喜欢
export const getLiked = (params = {}) => {
    return http.get('/api/liked', params)
}
// 喜欢音乐列表
export const getFavoriteList = (params = {}) => {
    return http.get('/api/favoriteList', params)
}
// 我的信息
export const myInfo = (params = {}) => {
    return http.get('/api/myInfo', params)
}
// 修改个人信息
export const editMyinfo = (params = {}) => {
    return http.post('/api/editMyinfo', params)
}
// 新增银行卡
export const addBank = (params = {}) => {
    return http.post('/api/addBank', params)
}
// 删除银行卡
export const delBank = (params = {}) => {
    return http.post('/api/delBank', params)
}
// 修改电话和邮箱
export const updateInfo = (params = {}) => {
    return http.post('/api/updateInfo', params)
}
// 修改登录密码
export const updatePassword = (params = {}) => {
    return http.post('/api/updatePassword', params)
}
// 设置支付密码
export const setPaypwd = (params = {}) => {
    return http.post('/api/setPaypwd', params)
}
// 修改支付密码
export const updatePatpwd = (params = {}) => {
    return http.post('/api/updatePatpwd', params)
}
// 团队信息
export const groupData = (params = {}) => {
    return http.get('/api/groupData', params)
}
// 新增邀请码
export const addGroupCode = (params = {}) => {
    return http.post('/api/addGroupCode', params)
}
// 申请提现
export const withdrawCash = (params = {}) => {
    return http.get('/api/withdrawCash', params)
}
// 收支明细
export const incomeLog = (params = {}) => {
    return http.get('/api/incomeLog', params)
}
// 用户添加邀请码
export const inviteCode = (params = {}) => {
    return http.get('/api/inviteCode', params)
}
// 银行列表
export const bankList = (params = {}) => {
    return http.get('/api/bankList', params)
}
//已领取的音乐列表
export const beatMuiscList = (params = {}) => {
    return http.get('/api/beatMusicList', params)
}
//支付结果验证
export const getCheckOrder = (params = {}) => {
    return http.get('/api/checkOrder', params)
}
// 已锁定的歌曲
export const myLockMusic = (params = {}) => {
    return http.get('/api/myLockMusic', params)
}
//编辑作品
export const editMusic = (params = {}) => {
    return http.post('/api/editMusic', params)
}
//修改协作区音乐
export const editBeatMusic = (params = {}) => {
    return http.post('/api/editBeatMusic', params)
}
//单曲音乐详情
export const showMusic = (params = {}) => {
    return http.get('/api/showMusic', params)
}
//取消领取的beat
export const cancelBeat = (params = {}) => {
    return http.post('/api/cancelBeat', params)
}
// 取消订单
export const cancelOrder = (params = {}) => {
    return http.get('/api/cancelOrder', params)
}
//请出团队
export const outGroupUser = (params = {}) => {
    return http.post('/api/outGroupUser', params)
}
//续费试听
export const activateAudition = (params = {}) => {
    return http.post('/api/activateAudition', params)
}
// 协作区已完成作品列表
export const completedDemo = (params = {}) => {
    return http.get('/api/completedDemo', params)
}
// 加入团队
export const joinGroup = (params = {}) => {
    return http.post('/api/joinGroup', params)
}
// 获取beat信息
export const getBeatMusic = (params = {}) => {
    return http.get('/api/getBeatMusic', params)
}
// 充值
export const recharge = (params = {}) => {
    return http.post('/api/recharge', params)
}

// 订阅包 时间
export const subscriptList = (params = {}) => {
    return http.get('/api/subscriptionList', params)
}
// 购买bgm
export const buyBgm = (params = {}) => {
    return http.post('/api/buyBgm', params)
}

// 创建团队
export const createGroup = (params = {}) => {
    return http.get('/api/createGroup', params)
}
// 团队歌曲列表
export const groupMusic = (params = {}) => {
    return http.post('/api/groupMusic', params)
}
// 企业认证
export const registerEnterprise = (params = {}) => {
    return http.post('/api/registerEnterprise', params)
}
// 审核作品
export const groupExamineMusic = (params = {}) => {
    return http.get('/api/groupExamineMusic', params)
}

// AI作曲 - 生成歌词
export const generateLyrics = (params = {}) => {
    return http.post('/api/generateLyrics', params)
}

// 积分基础套餐
export const aiStyleDataBase = (params = {}) => {
    return http.get('/api/aiStyleDataBase', params)
}

// AI作曲
export const musicAIMake = (params = {}) => {
    return http.post('/api/musicAIMake', params)
}

//AI获取我的作品
export const musicAIGet = (params = {}) => {
    return http.get('/api/musicAIGet', params)
}

//AI获取我的作品新
export const musicAIGetList = (params = {}) => {
    return http.get('/api/musicAIGetList', params)
}

//金额兑换积分
export const moneyExchangeIntegral = (params = {}) => {
    return http.post('/api/moneyExchangeIntegral', params)
}

//积分明细
export const integralLogGet = (params = {}) => {
    return http.get('/api/integralLogGet', params)
}

//充值明细
export const rechargeLogGet = (params = {}) => {
    return http.get('/api/rechargeLogGet', params)
}

// 积分基础套餐
export const integralDataBase = (params = {}) => {
    return http.get('/api/integralDataBase', params)
}

// paypal充值
export const paypalpay = (params = {}) => {
    return http.post('/api/paypalpay', params)
}

//卡密兑换积分
export const cardNumberExchangeIntegral = (params = {}) => {
    return http.post('/api/cardNumberExchangeIntegral', params)
}

// 声音模板
export const musicfyGetVoice = (params = {}) => {
    return http.get('/api/musicfyGetVoice', params)
}

//musicfy生成
export const musicfyCreate = (params = {}) => {
    return http.post('/api/musicfyCreate', params)
}

// musicfy列表
export const musicfyGetList = (params = {}) => {
    return http.get('/api/musicfyGetList', params)
}