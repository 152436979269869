import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'

import en from './langs/en'
import cn from './langs/cn'
import ko from './langs/ko'

Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: localStorage.lang || 'cn',
  messages: {
    en, cn, ko
  }
})

locale.i18n((key, value) => i18n.t(key, value))

export default i18n