export const defaultColor = {
    primaryColor: 'skyblue',
    primaryTextColor: 'skyblue',
    baseBackgroundColor: '#ffffff',
    footerBackgroundColor: '#222426',
    recommendMusicianListBGColor: '#F5F5F5',
    recommendMusicianListTextColor: '#222426',
    recommendMusicianListTitleColor: '#999999',
    recommendMusicianListButtontBGColor: '#D4D4D4',
    headerSelectTextColor: '#222426',
    headerTextColor: '#999999',
    headerSearchBg: '#F5F5F5',
    messageIconColor: '#2D2F31',
    iconListBGColor: 'rgba(212, 212, 212, 0.2000)',
    musicLibraryFilterMenuNavBGColor: '#F9F9F9',
    musicLibraryFilterMenuNavTextColor: '#222426',
    musicLibraryFilterMenuNavTitleColor: '#222426',
    demoitemsongnameColor: '#222426',
    demoitemsongMusicianColor: '#666666',
    demoitemtabsColor: '#999999',
    demoitemtabsBGColor: 'rgba(212,212,212,0.2)',
    demoitemBGC: '#F5F5F5',
    uploadInputBackgroundColor: '#fff',
    popupBackgroundColor: '#FFFFFF',
    popupCommonBorderColor: 'rgba(0, 0, 0, 0.0600)',
    popupCancelBgColor: '#FFFFFF',
    popupCancelTextColor: '#3370FF',
    popupCancelBorderColor: '#3370FF',
    popupLockTimeBorderColor: '#D4D4D4',
    uploadFormTextColor: '#2a2424',
    popupgray: 'gray',
    //选中背景色
    truebuttonBgColor: ' rgba(51,112,255,0.06)',
    // 按钮选中边框颜色
    truebuttonBorderBgColor: '#3370FF',
    //按钮选中文字颜色
    truebuttonTextColor: '#3370FF',
    //正常按钮背景色
    // buttonBgColor: '#FFFFFF',
	buttonBgColor: 'rgba(255, 255, 255, 0.08)',
    //正常按钮边框
    buttonBorderBgColor: '#D4D4D4',
    //正常按钮文字颜色
    buttonTextColor: '#222426',
    iconColorAll:'#666666',
    loginInputBgColor: 'rgba(255, 255, 255, 0.8)',
    loginTopLogoBorderColor: 'rgba(0, 0, 0, .2)',
    loginTopLogoBgColor: '#fff',
	personInfoFormColor: '#EEEEEE',
	personInfoRadioBgColor: '#FFFFFF',
    lineColor:'#000000',
	tableHoverBgColor: '#E4F2FF',
	collectYellow: '#FEC002',
    inputHoverColor: '#3370FF',
    tagBgColor: 'rgba(0, 0, 0, 0.08)',
	songBgColor: '#F6F8FE',
	iconColorSwiperBtn: '#818292',
    musicPlayerBgColor: 'rgb(255, 255, 255)',
	loginPopColor: 'rgba(255, 255, 255, 0.90)',
	messageLineColor: 'rgba(34, 36, 38, 0.06)',
    aigecijiazaiBackgroundColor: '#F5F5F5',
}