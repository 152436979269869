import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
  ...enLocale,
	// recommend
	recommend: {
		recommendhome: 'recommend',
		musician: 'Musician',
		works: 'Works',
		fans: 'Fans',
		followed: 'Followed',
		noFollow: 'Follow',
		find: 'Find',
		focusSuccess: 'Focus on success',
		finishedMusic: 'Finished music',
		demoSea: 'DEMO (overseas)',
		demoLocal: 'DEMO (mainland)',
		enterMusicLibrary: 'Enter the music library',
		WorkCollaboration: 'Work collaboration area',
		loginSucceeded: 'Login successful',
		loginSucceededContent: "Dear user, you have logged in successfully,you will be given ten minutes of listening permission.The platform implements the protection mechanism of musicians' original creation,therefore, after the expiration of trial permission you can choose enterprise certification, musician certification or recharge,otherwise, the platform has the right to block your account,please be informed that inform~",
		cancel: 'Cancel',
		confirm: 'Confirm',
		canceldel: "The deletion has been canceled",
		popupJurisdiction: 'prompt',
		popupJurisdictionContent: 'Dear user, your one-month audition permission has expired, because the platform implements the original protection mechanism of musicians, please click the link below to go to enterprise certification, artist certification or recharge. Otherwise, the platform has the right to ban your account, Please be notified~',
		enterpriseCertification: 'Click Go to Enterprise Certification Now',
		musicianCertification: 'Click GO  to Musician Certification Now',
		accountRecharging: 'Click Go to top up your account Now',
		payImmediately: 'Pay now',
		completePayment: 'Complete the payment',
		transferPopupTitle: 'Payment tips',
		transferPopupText1: 'The system has detected that you have not paid successfully',
		transferPopupText2: 'You may not have paid/transferred successfully',
		transferPopupText3: "You may have paid successfully, but you didn't upload proof of payment",
		transferPopupText4: "You didn't upload a proof of payment",
		singer: 'Singer',
		more: 'More',
		buyNow: 'buy',
		authorizedTerritory: 'Authorized region',
		authorizedType: 'Authorization type',
		authorizedyear: "Authorization year",
		selectcouppon: "Select the coupon",
		lockTime: 'Lock time',
		day: 'day',
		orderSn: 'Order number',
		all: 'all',
		please: 'Please',
		orderCloseTitle: 'Payment should be completed, otherwise the order will be closed',
		amountPayable: 'Amount due',
		paymentMethod: 'Supported payment methods',
		weChatPayment: 'WeChat Pay',
		alipayPayment: 'Alipay payment',
		corporateTransfer: 'Transfer money to business',
		corporateTransferInfo: 'Transfer information to the business',
		corporateName: 'Name',
		bankDeposit: 'Account bank',
		bankAccount: 'Account',
		paymentVoucher: 'Upload your payment voucher',
		clickUpload: 'Click Upload',
		format: 'Format',
		buysubscribe: 'Purchase a subscription package',
		subscribetime: "Subscription time",
		authorizedTerritoryText: 'Mainland',
		authorizationTypeText: 'Recording rights (recording rights, dissemination rights, reproduction rights)',
		copysuccess: "Copy the song successfully",
		copyfaild: "Copying songs failed",
		slowly: "Slowly",
		middle: "Middle",
		faster: "Faster",
		man: "Full",
		payweixin: "Wechat",
		payapliy: "Alipay",
		uploadBeat: "upload Beat",
		copyCodeFailed: '复制邀请码失败',
		collection: '收藏',
		fenxiang: '分享',
		suoding: '锁定',
		cancelCollectin: "取消收藏",
		xiazai: "下载",
	},
	// customize
	Customization: {
		selectMusician: 'Select musician',
		addMusician: 'Add musicians',
		basicInformation: 'Introduction to basic information',
		songName: 'Song name',
		proposedSinger: 'Proposed singer',
		introductionSingers: 'Singer introduction',
		projectIntroduction: 'Project Introduction',
		songUsage: 'Song usage',
		demandBackground: 'Background/purpose of the requirement',
		targetAudience: 'target audience',
		lyrics: 'Lyric content/theme/core expression',
		keyWordsSongs: 'Song keywords',
		songDirection: 'Song direction',
		qufengPositioning: 'Genre positioning',
		referenceSong: 'Reference Song',
		releaseTime: 'Closing deadline and release time',
		release: 'Release',
		selectDate: 'Select date',
		selectTime: 'select time',
		submitApplication: 'Submit  application',
		addtotal: "total",
		mingmusicial: "musicians",
		pleaseinputproposedsinger: "Please enter the Proposed singer",
		pleaseinputintruducesinger: "Please enter the Singer introduction",
		pleaseinputintruduceproject: "Please enter the Project Introduction",
		longnotmorefive: "The length cannot exceed 500 characters",
		pleaseinputkeywords: "Please enter the Song keywords",
		pleaseinputpurposesong: "Please enter the Song usage",
		pleaseinputbackground: "Please enter the Background/purpose of the requirement",
		pleaseinputtargetaudience: "Please enter the target audience",
		pleaseinputtheme: "Please enter the Lyric content/theme/core expression",
		longnotmoretwo: "The length cannot exceed 200 characters",
		pleaseinputposition: "Please enter the Genre positioning",
		pleaseinputref: "Please enter the Reference song",
		pleasedeadlinetime: "Please select an expiration date",
		pleasereleasetime: "Please select the release time",
		hasaddmusicianlist: "Added to the list of musician"
	},
	// Music library
	musiclibraryObj: {
		shop: 'Buy now',
		song: 'Claim rhythm',
		claim: 'Claim arranger'
	},
	haveexpired: 'Expired',
	notstart: 'Not started',
	underway: 'In progress',
	songcollection: 'Call for songs',
	discountsection: 'Discount zone',
	messagecenter: 'Message center',
	musiclibrary: 'Music library',
	musicAI: 'AI',
	mymusicAI: 'My Works',
	musicaigequmiaoshu: 'Song description',
	musicaigequmiaoshutt: 'Describe your desired musical style and theme (e.g. "About holidays"). Use genres and atmospheres rather than specific artists and songs.',
	musicaigequgeci: 'Generate random lyrics, write your own, or get some help from Al. Use two poems (8 lines) for best results.',
	musicaigequfenggei: 'Describe your desired style of music e.g. Pop, rock, epic. Our model doesn\'t recognize specific artist names, but it does understand music genres and atmospheres.',
	musicaigequtitle: 'Name your song',
	musicaigequgegeci: 'lyric',
	musicaigequgemakegeci: 'Generate lyrics',
	musicaigequstyle: 'Musical style',
	musicaigequgett: 'Song title',
	musicaigequgeciinput: 'Type in your lyrics',
	musicaigequstyleinput: 'Enter music style (please use English)',
	musicaigequgecittinput: 'Enter music name',
	musicaigequinput: 'Please enter your song description, for example: Write a Japanese pop song about love',
	musicaichunyinyue: 'Pure music',
	musicaichunyinyuett: 'Pure music without lyrics',
	musicaibuttonchuangzuo: 'Start the work',
	musicaimiaoshu: 'Description',
	musicailistkong: 'No works',
	musicaixiazaiyinyue: 'Download',
	musicaichakangeci: 'lyric',
	musicaiwodejifen: 'My points',
	musicaixiaohaojifen: '10 points per creation',
	activityarea: 'Event area',
	songcustomization: 'customization',
	finishedmusic: 'Finished music',
	searchprompt: 'The name and number of the searched song',
	workupload: 'upload',
	overseas: 'Overseas',
	hinterland: 'Mainland',
	songName: 'Song name',
	producer: 'Producer',
	singer: 'Singer',
	Style: 'Style',
	instruments: 'Instruments',
	speed: 'Speed',
	filtrate: 'screening',
	writWords: 'lyricist',
	compose: 'Lyrics/Composition/Arrangement',
	arrangement: 'Lyrics/Composition',
	arrangementbeat: 'Composition',
	lyricsText: "lyrics",
	singSinger: 'Suitable for singers',
	pleaseSelectSuitSinger: 'Please select the songer for which the song is suitable',
	pleaseSelectSongStyle: 'Please select the style of the song',
	pleaseSelectSongUseInstrument: 'Please select the instrument used for the song',
	pleaseSelectSongMood: 'Please select the mood of the song',
	songStyle: 'Song style',
	musicalInstrument: 'musical instrument',
	claimwork: 'The work I claimed',
	audiofile: 'Audio files',
	uploadlyrics: 'Upload the lyrics',
	collaborate: 'Whether to collaborate',
	applylyrics: 'Apply for words',
	guidesing: 'Apply for lead vocals',
	emotion: 'emotion',
	readAndAgree: 'Read and agree',
	musicianOccupancyAgreement: 'Musician Residency Agreement',
	clickUpload: 'Click Upload',
	publishWork: 'Publish your work',
	uploadTip: 'Support jpg/png format, size 196*196px, maximum not more than 10M',
	vCodeLoginText: 'SMS login',
	passwordLoginText: 'Password login',
	mobile: 'Mobile phone number',
	inputmobile: 'Please enter your mobile phone number',
	placeholder: 'Please enter',
	vCodeText: 'verification code',
	forgotPassword: 'Forgot password',
	signUpNow: 'Sign up now',
	testGetCode: 'Get a verification code',
	tryAgainInSeconds: 'Try again in seconds',
	email: 'E-mail',
	password: 'Password',
	login: 'Login',
	userName: 'User name',
	invitationCode: 'InvitationCode',
	register: 'Register',
	agree: 'Agree',
	userAgreement: '《Useragreement》',
	privacyPolicy: '《Privacy Policy》',
	confirm: 'Confirm',
	alreadyHaveAnAccountToRegister: 'Already have an account, log in now',
	thePasswordEnteredTwiceDoesNotMatch: 'The password entered twice does not match!',
	theMailboxFormatIsIncorrect: 'The mailbox format is incorrect',
	agreeToTheAgreement: 'Please read and agree to the agreement after registering!',
	Collaboration: {
		toobig: "The file is too large",
		rightAr: "Please upload the AR file in the correct format",
		rightmr: "Please upload the MR file in the correct format",
		rightmulti: "Please upload the Multi file in the correct format",
		rightAudio: "Please upload the Audio file in the correct format",
		rightLyrics: "Please upload the Lyric file in the correct format",
		rightcover: "Please upload a cover page in the correct format",
		shangjiaxuzhi: "Listing instructions",
		xuzhi1: "When uploading, you must ensure that your work is original and undistributed；",
		xuzhi2: "The entry will be reviewed within 72 hours；",
		xuzhi3: "If you want to remove an original work that has been approved, please submit a takedown application seven days in advance；",
		xuzhi4: "If the transaction of the work is successful during the review period, the platform order will be the mainstay。",
		confirm: "Confirm",
		upsuccess_title: "The release was successful",
		upsuccess_p1: "The work is successfully published and is waiting for the administrator to review！",
		upsuccess_p2: "We will review and approve it for you within 72 hours, please be patient。",
		collaborationsuccuess: "The customization application was submitted successfully",
	},
	Demosea: {
		receivedSuccessfullyWhetherToDownload: "Successful collection, whether to download the file?",
		tip: "Tips",
		cancel: "Cancel",
	},
	UploadOpus: {
		noRegisteredMusician: 'You are not registered as an artist and cannot upload at the moment',
		twotepCertType1: "Citizen ID card",
		twotepCertType2: "passport",
		twotepCertType3: "Mainland Travel Permit for Hong Kong and Macao Residents",
		twotepCertType4: "Mainland Travel Permit for Taiwan Residents",
		twotepCertType5: "Resident household register",
	},
	//My Page
	mywallet: 'My wallet',
	myworks: 'My works',
	myinterest: 'My follows',
	myfavorite: 'My Collection',
	myclaim: 'My claim',
	mypurchase: 'My purchases',
	mycustom: 'My customization',
	AccountKeeper: 'Account Management',
	joinorganization: 'Join the organization',
	businessmanagement: 'Business management',
	logout: 'Sign out',
	teammanagement: 'Team management',
	accountbalance: 'Account balance',
	bindbankcard: 'Bind a bank card',
	Listenpermission: 'Audition permissions',
	mycoupon: 'My coupons',
	grossearnings: 'Gross income',
	Todayincome: "Today's income",
	Todayexpend: "Today's expenditures",
	cashwithdrawalamount: 'Withdrawal amount',
	revenuedetail: 'Revenuedetail',
	requestwithdrawal: 'Request a withdrawal',
	havebought: 'Purchased',
	reserved: 'Locked',
	all: 'all',
	havepaid: 'Paid',
	nonpayment: 'Not paid',
	authorize_title: 'Authorization Information',
	authorize_p1: "I (Name)",
	authorize_p2: "ID number",
	authorize_p3: "(hereinafter referred to as 'Licensor') is the right holder of the copyright (including performer's right) and neighboring rights of the songwriters, performers, and sound producers of phonograms listed in the Annex to this Authorization Letter, and hereby authorizes xxxx Company and its affiliates (hereinafter referred to as 'licensees') to exclusively represent and operate the authorized works of copyright and neighboring rights enjoyed by the licensor (xx) on the channels and platforms provided by Internet and telecommunications network operators within the scope of Chinese mainland。",
	authorize_p4: "Scope of authorization",
	authorize_p5: "1. Exclusively develop and operate on Internet services (including but not limited to online browsing, playing, downloading of works, etc.) and value-added telecommunications services (including but not limited to personalized ringback tone, ring tone, IVR, WAP, SMS, MMS, full song download, mobile phone video, mobile phone built-in, etc.) and use it for Internet services and value-added telecommunications services, and sub-authorize third parties to develop and operate and use Internet services and value-added telecommunications services。2. Rip, modify, adapt, translate and other processing and production of works based on the license of the licensor (xx), assign value, distribute, rent, and disseminate to the public through the information network for the production of the product。3. The acting licensor (xx) shall pursue liability and litigation against the infringing unit or individual when the rights and interests such as copyright or neighboring rights of the work are infringed.",
	authorize_p6: "(xx) Company and its affiliates may, on their own or by authorized partners, use the relevant images and works provided by the licensor (xx) through the following channels for the purpose of promoting the cooperative works，",
	authorize_p7: "Including but not limited to:",
	authorize_p8: "Promotion through print, television and radio media, mobile terminals, audio-visual products and other channels or carriers。",
	authorize_p9: "Promotion through FLASFH, MV, video, etc.",
	authorize_p10: "Through CD, DVD, cassettes and other carriers, the album surface printing or promotional leaflet and other ways to promote。",
	authorize_p11: "Use of licensed content (including photos, MVs, etc. provided by licensees) in online advertisements, media advertisements, and promotional activities。",
	authorize_p12: "Use the portrait pictures and related materials provided by the authorized person to make promotional pages, experience cards and other publicity materials for distribution。",
	authorize_p13: "Authorization period: from xx year x month xx to xx year x month xx day. Within 30 days prior to the expiry of the License, the Parties will negotiate the renewal or modification of this Authorization。",
	authorize_p14: "It is hereby authorized",
	authorize_p15: "Authorized Parties",
	unfinished: 'Unfinished',
	completed: 'Completed',
	canceled: 'Canceled',
	deadline: 'Deadline',
	issuedate: 'Song release time',
	customcoding: 'Custom coding',
	submitwork: 'Submit your work',
	generateinvitationcode: 'Generate an invitation code',
	exclusiveinvitationcode: 'Exclusive invitation code',
	copyinvitationcode: 'Copy the invitation code',
	invitationcodevalidityperiod: 'Each invitation code is valid for 14 days, and the single code can be used once',
	personaldetails: 'Personaldetails',
	realname: 'Real-name authentication',
	enterprise: 'Enterprise certification',
	accountsecurity: 'Account security',
	stagename: 'Stage name (byline)',
	sex: 'gender',
	personalprofile: 'Personalprofile',
	finished: 'Finished',
	enterpersonalprofile: 'Please enter your Personalprofile',
	enterstagename: 'Please enter your stage name (byline)',
	selectgender: "Please select a gender",
	inputbriefintroduction: "Please enter a brief introduction",
	man: 'Man',
	woman: 'Woman',
	enterrealname: 'Please enter your real name',
	regions: 'Country region',
	enterregions: 'Please enter your country region',
	documentnum: 'ID number',
	enterdocumentnum: 'Please enter your ID number',
	documentphoto: 'ID photo',
	enterdocumentphoto: 'ID / Passport (front)',
	submitreport: 'Submit for review',
	businessname: 'Businessname',
	businesscontent: 'Only Chinese mainland enterprise certifications are supported',
	businesslogo: 'Corporate logo',
	limits: 'Support jpg/png format, size 196*196px, maximum not more than 10M',
	businesslicense: 'License',
	emailaccounts: 'Email account',
	emailaccountstitle: 'You can use a secure mailbox',
	telaccounts: 'Mobile phone account',
	telaccountstitle: 'Mobile phones can be used',
	loginpassword: 'Login password',
	loginpasswordtitle: 'Password requirements contain at least two letters, symbols or numbers and are longer than 8 characters, it is recommended that you change your password frequently to keep your account more secure。',
	paypassword: 'Payment password',
	paypasswordtitle: 'The password requires 6 digits, and it is recommended that you change the payment password frequently to ensure that the account is more secure。',
	setting: 'Set up',
	revision: 'revise',
	changes: 'change',
	Lognow: 'Log in now',
	changeemail: 'Change the mailbox',
	newemail: 'New mailbox',
	emailtitle: 'Please fill in your new email address',
	authcode: 'Captcha',
	haveauthcode: 'Get a verification code',
	authcodetitle: 'Please fill in the verification code sent to your mobile phone',
	changemobile: 'Change your phone number',
	newmobile: 'New phone number',
	newmobiletitle: 'Fill in your new mobile number',
	changeloginpassword: 'Change the login password',
	oldpassword: 'Old password',
	oldpasswordtitle: 'Please fill in your old password',
	newpassword: 'New password',
	newpasswordtitle: 'Please fill in your new password',
	subpassword: 'Confirm the password',
	setloginpassword: 'Set a login password',
	enterpaymentpassword: 'Enter the payment password',
	subpaymentpassword: 'Confirm the payment password',
	setpaypassword: 'Set a payment password',
	nowpaypassword: 'Current payment password',
	newpaypassword: 'New payment password',
	subpaypassword: 'Confirm the payment password',
	incomedetail: 'Breakdown of income and expenditure',
	accounttitle: 'Account name',
	variationquantity: 'Number of changes',
	rechargechannel: 'Recharge channels',
	time: 'Time',
	recharge: 'recharge',
	rechargemoney: 'Top-up amount',
	inputrechargemoney: 'Please enter the amount you want to top-up',
	withdraw: 'Withdrawals',
	changebankcard: 'Please select a bank card',
	withdrawtitle: 'Please enter the amount you wish to withdraw',
	withdrawalamount: 'Amount of cash withdrawable',
	myintegral: 'integral',
	mydeintegral: 'Point balance',
	exchangeintegral: 'Redeem points',
	exchangemoney: 'Exchange amount',
	exchangemoneynono: 'Please enter the exchange amount',
	integralDetail: "Integral detail",
	integraltypetxt: 'way',
	integralshengyutxt: 'Residual integral',
	paypaljine: 'Purchase amount',
	paypalzengsongjifen: 'Bonus points',
	integralshijiantxt: 'time',
	rechargeshengyu: 'Residual amount',
	bankcard: 'Bank cards',
	bankcardnum: 'Bank card number',
	inputbankcardnum: 'Please enter your bank card number',
	close: 'Close',
	success: 'succeed',
	bindsuccess: "Binding succeeded",
	withdrawalamountnonull: 'The withdrawable cash amount cannot be empty',
	removebind: 'Relieve bundled',
	name: 'Name',
	inputname: 'Please enter your name',
	IDnum: 'ID number',
	inputIDnum: 'Please enter your ID number',
	inputverificationcode: 'Please fill in the verification code sent to your mobile phone',
	verificationcode: 'Verificationcode:',
	topupdetails: 'Top-up details',
	listeningcard: 'Audition access cards',
	expire: 'Expire',
	timesrenewal: 'There are renewals left',
	frequency: 'frequency',
	Listeningprivilege: 'Audition privileges',
	srenewal: 'Renewal',
	Renewaltime: 'Duration of renewal',
	Renewaltitle: '*During the preview period, you can listen to all songs on the platform。',
	notlogin: "You are not logged in",
	loginmore: "Sign in to experience more features",
	promptlylogin: "Log in now",
	mannedplatform: "Onboarding",
	uploadingworks: "Upload",
	workspurchase: "purchased",
	numberofrenewals: "There are renewals left",
	applyfor: "times, you need to apply again when the number of times is used up",
	discountcoupon: "Coupons",
	full: "Full",
	usable: "available",
	yuan: "RMB",
	touse: "Go for use",
	haveused: "Used",
	unverified: "Not certified",
	authenticated: "Certified",
	WeChatAccount: "WeChat",
	weibo: "Weibo",
	aboutus: "About us",
	useragreement: "User Agreement",
	privacypolicy: "Privacy Policy",
	contactus: "Contact us",
	highquality: "The world's high-quality original music DEMO library",
	dark: "Dark theme",
	bright: "Bright theme",
	ICP: "辽ICP备2020014486号-1",
	claimedwork: "Please select your claimed entry",
	songname:"Please enter a song name",
	collaboratenot:"Whether to collaborate",
	fillword: "I want to write lyrics",
	songword: "lyrics",
	seesongword: "View the lyrics",
	replaceword: "Replace the lyrics",
	nextword: "Next lyrics",
	Musiccertification: "Musician certification",
	artname: "Stage name/byline",
	songartname: "The byline at the time of the song's release",
	isrealname: "Real name",
	isrealnametitle: "Please enter your real name, which cannot be changed after approval",
	country: "nationality",
	selectcountry: "Please select a nationality",
	mobiletitle: "For official contact with you",
	desc: "Personal description",
	deschome: "will appear on your profile",
	Uploadsongtitle: "The name of the song you uploaded",
	workType: "Type of work",
	seademo: "DEMO(overseas)",
	songspeed: "Please enter the exact tempo of your song",
	songtone: "Please select the exact pitch of your song",
	cover: "cover",
	moremusician: "More musicians",
	moreMusic: "More music",
	Accompanimentclaim: "If the accompaniment is successfully claimed, please upload the work in time",
	Unabletoupload: "If you are unable to upload your work, please cancel your claim in time",
	nomore: "No more",
	Lowerarrangement: "Download the arranger",
	user: "User",
	isenterprise: "enterprise",
	allpreferential: "full discount details",
	totalcommodityprice: "The total price of the item",
	coupondeduction: "Coupon deduction",
	locksonggold: "locked song deduction",
	Generalpreference: "Total offers",
	total: "Total",
	preferentialdetails: "Preferentialdetails",
	price: "Price",
	Uploadcomposition: "Upload the composition",
	audited: "Audited",
	reviewed: "To be reviewed",
	modified: "To be modified",
	nopass: "Failed",
	Soldout: "Sold",
	Lockcountdown: "Lock the countdown",
	buytype: "Type of purchase",
	authorizedarea: "Authorized region",
	authorizedtype: "Authorization type",
	Disbandteam: "Disband the team",
	Handoverteam: "Handover the team",
	Outteam: "Please get out of the team",
	teammembers: "Team members",
	remove: "Delisted",
	registerEmail: {
		emailinput: 'Please enter your email address',
		he: 'and'
	},
	cancelClaim: "Cancel claim",
	uploadtext: "Download",
	namecomponent: "Name：",
	tranfercomponentinfo: "Shenyang Tiji Music Culture Media Co., Ltd",
	bankaccountinfo: "China Construction Bank Shenyang Nanjing Street Sub-branch",
	pleaseclaimbeat: "Please pick up the arrangement first",
	orderhascreate: "The order has been generated, please upload the voucher after the transfer!",
	paysuccess: "Payment successful",
	addsuccess: "The addition succeeded",
	audioplayfaild: "The audio failed to load and cannot be played！",
	playhistory: "Play the recording",
	confirmDel: "Confirm removal",
	coupponnotuse: "Coupons are not available",
	paymethodtext: "Payment Methods",
	submitsuccesstext: "The submission was successful",
	finishrecommand: "We will arrange musicians for you as soon as possible to complete your needs",

	nopermissionbrowse: "Oh~~You do not have permission to view this page~",
	pageislost: "The page is lost! Look at something else~",
	theservercrashed: "Oh, my God, The server crashed…",
	backhome: "Return to the home page",
	backpreviouspage: "Go back to the previous page",
	countryChina: "China",
	nonextlyrics: "No next lyrics",
	realnameauthsucceeded: "You have successfully authenticated your real name",
	certifiedauthsucceeded: "You have successfully certified your enterprise",
	collectionworks: "Collection of works",
	managingmembers: "Manage members",
	inviteteammembers: "Invite team members",
	optiondelforever: "This operation will permanently delete the order. Do you want to continue?",
	renewalsucceeded: "Successful renewal",
	renewalfaild: "Renewal failure",
	createteam: "Create Teams Now",
	teamname: "Team name",
	teamnamesuit: "Please enter a name suitable for your team",
	creater: "founder",
	createtext: "create",
	minsix: "The length is between 6 and 20 characters",
	minthree: "The length is 3 to 5 characters",
	createteamtabs: "Create a team",
	reviewworks: "Review the work",
	phoneregister: "Mobile phone number registration",
	emailregister: "Email registration",
	resetpassword: "Reset your password",
	inputactivename: "Please enter a name for the event",
	selectactivearea: "Please select an active region",
	selectdata: "Please select a date",
	pleaseselecttime: "Please select a time",
	atleastselectactive: "Please select at least one activity nature",
	selectactivereasource: "Please select an active resource",
	inputactivemethods: "Please fill in the event form",
	limitselection: "The current limit is 1 file, this time selected",
	Selected: "Files，Selected",
	filenum: "Files",
	creatornotnull: "Producer cannot be empty",
	selectclaimwork: "Please select a claimed entry",
	audionotnull: "The audio file cannot be empty",
	lyricsnotnull: "The lyric file cannot be empty",
	demoupnotifytitle:"Error",
	supportmp3: "Support mp3, the size does not exceed 100M",
	supportpng: "Support jpg/png format, the size does not exceed 10M",
	uploadAr: "Please upload AR",
	uploadfomat: "Uploading avatar images can only be in JPG format!",
	uploadsize: "The size of the uploaded avatar image cannot exceed 2MB!",
	uploadheadimgformat: "The uploaded avatar is not formatted correctly",
	authenticationrightcertimg:"Please upload a photo in the correct format",
	checkmusicianagreement: "Please check the Artist Residency Agreement",
	mylove: "My love",
	mypurchases: "My purchases",
	mycustomize: "My customize",
	myaccount: "My Account",
	headImg: 'head sculpture',
	enterpriseAgreement: "Enterprise Registration Agreement",
	enterpriseTitleNotNull: "Enterprise title cannot be empty",
	businesslicenseNotNull: "Business license cannot be empty",
	enterpriseLogoNotNull: "Enterprise LOGO cannot be empty",
	pleaseCheckEnterpriseAgree: "Please check the enterprise registration agreement",
	readmusicianagreement: "Please read and agree to the Musician Admission Agreement",
	revenueDetail: "Revenue details",
	revenueMethods: "Revenue channel",
	kamiinputplace: 'Please enter your email address.',
	amichongzhi: 'Pcardi charge',

}

export default en