import http from '@/utils/request/http'

// 获取验证码
export const getVCode = (params = {}) => {
    return http.get('/api/sendCode', params)
}

// 获取地区手机编码
export const getTelephoneCode = (params = {}) => {
    return http.get('/api/getTelephoneCode', params)
}
// 获取适合歌手
export const suitList = (params = {}) => {
    return http.get('/api/suitList', params)
}
// 获取忘记密码验证手机号码
export const checkMobile = (params = {}) => {
    return http.post('/api/checkMobile', params)
}
// 修改密码
export const forgetPassword = (params = {}) => {
    return http.post('/api/forgetPassword', params)
}