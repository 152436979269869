import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "@/router/routers";
import store from '@/store'
import {getToken} from "@/utils/common";

Vue.use(VueRouter)


const router = new VueRouter({
	routes,
	scrollBehavior: (to, from, savedPosition) => {
		if (savedPosition) {
			return savedPosition
		} else {
			return {y: 0}
		}
	}
})
router.beforeEach((to, from, next) => {
	const token = getToken()
	const LOGIN_PAGE_NAME = 'VCodeLogin'

	// header选择状态设置
	if(to.meta.headerSelectName){
		store.commit('changeHeaderSelectName', to.meta.headerSelectName)
	}

	// if(to.name == 'TotalRevenue' && store.state.identity != '3'){
	// 	router.push({
	// 		path: '/personalCenter/myWallet/accountBalance'
	// 	})
	// }
	console.log(to.name,store.state.groupType,store.state.isBoss,'router')
	if(to.name == 'CreateTeam' && store.state.groupType != '0' && store.state.isBoss == '1'){
		router.push({
			path: '/personalCenter/teamManagement/reviewWorks'
		})
	}
	if(to.name == 'CreateTeam' && store.state.groupType != '0'&& store.state.isBoss == '0'){
		router.push({
			path: '/personalCenter/teamManagement/managingMembers'
		})
	}

	if (!to.meta.isAuth) {
		next()
	} else if (!token && to.name !== LOGIN_PAGE_NAME) {
		router.push({
			path: '/login/vcodelogin'
		})
	} else {
		next()
	}
})

router.afterEach((to, from, next) => {
	window.scrollTo(0, 0)
})

// const router = new VueRouter({
//   mode: 'hash',
//   base: process.env.BASE_URL,
//   routes
// })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
