<template>
  <i class="icon-view">
    <svg
        :style="{
              fill: color,
              width: width + 'px',
              height: height + 'px'
            }"
        aria-hidden="true"
        ref="iconFvg"
    >
      <use :xlink:href="iconName"></use>
    </svg>
  </i>

</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false
    },
    themeColor: {
      type: String,
      required: false
    },
    width: {
      type: String,
      required: false,
      default: '24'
    },
    height: {
      type: String,
      required: false,
      default: '24'
    }
  },
  data() {
    return {}
  },
  components: {},
  computed: {
    iconName() {
      return this.name.indexOf('#') > -1 ? this.name : `#${this.name}`
    }
  },
  created() {
  },
  mounted() {
    // console.log(this.GlobalCSS[this.themeColor])
    this.$refs.iconFvg.style.fill = this.GlobalCSS[this.themeColor]
  },
  watch: {
    themeColor: {
      handler(newValue, oldValue) {
        console.log('主题icon颜色:', newValue)
        console.log(this.GlobalCSS[newValue])
        this.$refs.iconFvg.style.fill = this.GlobalCSS[newValue]
      }
    },
  },
  methods: {}
}
</script>

<style scoped lang='less'>
.icon-view {
  --Color: '';

  svg {
    fill: var(--Color);
  }
}
</style>
