import koLocale from 'element-ui/lib/locale/lang/ko'
const ko = {
  ...koLocale,
  hello: '안녕하세요',
	// 추천
	recommend: {
		recommendhome: '추천',
		musician: '뮤지션',
		works: '작품',
		fans: '팬',
		followed: '팔로우',
		noFollow: '팔로우하기',
		find: '찾기',
		focusSuccess: '팔로우완료',
		finishedMusic: '완곡작품',
		demoSea: 'DEMO (해외)',
		demoLocal: 'DEMO (중국)',
		enterMusicLibrary: '뮤직라이브러리로 가기',
		WorkCollaboration: '작품협업파트',
		loginSucceeded: '로그인 완성;',
		loginSucceededContent: '-사랑하는 고객님, 로그인 성공하셨습니다,7KEY MUSIC에서 10분동안의 리스닝 권한을 무료로 드립니다.플랫폼은 오리지널 음악 보호차원에서 리스닝시간이 지나면 기업인증, 뮤지션인증 또는 충전의 방식으로 계속 이용해주시기 바랍니다.인증하지 않은 사용자는 일정기간내로 플랫폼에서 제명되오니 이해하시기 바랍니다~',
		cancel: '캔슬',
		confirm: '확인',
		canceldel: "삭제 캔슬",
		popupJurisdiction: '힌트',
		popupJurisdictionContent: '존경하는 고객님，1개월 동안의 리스닝시간이 만기되어 플랫폼의 오리지널음악 보호규정에 따라 ，아래 링크로 기업인증/뮤지션인증 혹은 충전을 완성하시기 바랍니다.  인증이 무기한 딜레이될 경우 아이디 삭제될 가능성이 있으니 숙지하시기 바랍니다~',
		enterpriseCertification: '기업인증으로 바로가기',
		musicianCertification: '뮤지션 인증으로 바로가기',
		accountRecharging: '바로 충전하기',
		payImmediately: '바로결제',
		completePayment: '결제완료',
		transferPopupTitle: '결제상황',
		transferPopupText1: '결제 실패하였습니다',
		transferPopupText2: '미결제/계좌이체 실패',
		transferPopupText3: '결제성공,경제증빙이 필요하니 결제 증빙을 업로드 하세요',
		transferPopupText4: '정확한 증빙사진을 업로드 하세요',
		singer: '가수',
		more: '더보기',
		buyNow: '바로구입',
		authorizedTerritory: '라이센스 지역',
		authorizedType: "라이센스 기한",
		selectcouppon: "쿠폰선택",
		lockTime: '홀드기간',
		day: '일',
		orderSn: '주문서 번호',
		all: '전부',
		please: '해 주세요',
		orderCloseTitle: '내로 결제를 완성하지 않을 경우 주문이 닫힙니다',
		amountPayable: '결제금액',
		paymentMethod: '결제옵션',
		weChatPayment: '위쳇페이',
		alipayPayment: '알리페이',
		corporateTransfer: '기업 이체',
		corporateTransferInfo: '기업이체 정보',
		corporateName:'명칭',
		bankDeposit: '은행',
		bankAccount: '은행계좌 번호',
		paymentVoucher: '결제 증빙 업로드',
		clickUpload: '클릭하여 업로드',
		format: '격식',
		buysubscribe: '구독 패키지 구입',
		subscribetime: "구독 기간",
		authorizedTerritoryText: '대륙',
		authorizationTypeText: '녹음권（녹음권、공중송신권、복제권）',
		copysuccess: "복사 성공",
		copyfaild: "복사 실패",
		slowly: "하",
		middle: "중",
		faster: "상",
		man: "만",
		payweixin: "위쳇",
		payapliy: "알리페이",
		uploadBeat: "트랙 업로드",
		copyCodeFailed: '复制邀请码失败',
		collection: '收藏',
		fenxiang: '分享',
		suoding: '锁定',
		cancelCollectin: "取消收藏",
		xiazai: "下载",
	},
	// 맞춤제작
	Customization: {
		selectMusician: '뮤지션선택',
		addMusician: '뮤지션추가',
		basicInformation: '기초정보소개',
		songName: '노래제목',
		proposedSinger: '제안 가수',
		introductionSingers: '가수소개',
		projectIntroduction: '프로젝트소개',
		songUsage: '노래용도',
		demandBackground: '요청배경/목적',
		targetAudience: '타겟',
		lyrics: '가사내용/주제/포인트',
		keyWordsSongs: '가사 키워드',
		songDirection: '노래방향',
		qufengPositioning: '장르 포지션',
		referenceSong: '래퍼런스(Ref)',
		releaseTime: '요청곡 마감시간과 발행시간',
		release: '업로드',
		selectDate: '선택날자',
		selectTime: '선택시간',
		submitApplication: '신청제출',
		addtotal: "총",
		mingmusicial: "유명뮤지션",
		pleaseinputproposedsinger: "제안가수를 입력하세요",
		pleaseinputintruducesinger: "가수소개를 입력하세요",
		pleaseinputintruduceproject: "프로젝트 소개를 입력하세요",
		longnotmorefive: "텍스트를 500자 이내로 적어 주세요",
		pleaseinputkeywords: "노래 키워드를 입력하세요",
		pleaseinputpurposesong: "노래 용도를 입력하세요",
		pleaseinputbackground: "수요 배경/목적을 적어주세요",
		pleaseinputtargetaudience: "타겟을 적어주세요",
		pleaseinputtheme: "가사내용/주제/포인트를 입력하세요",
		longnotmoretwo: "텍스트를 200자 이내로 적어 주세요",
		pleaseinputposition: "곡 장르 포지션을 입력하세요",
		pleaseinputref: "래퍼런스를 입력하세요(Ref)",
		pleasedeadlinetime: "마감일을 선택하세요",
		pleasereleasetime: "발행시산을 선택하세요",
		hasaddmusicianlist: "뮤지션 리스트에 추가되었습니다"
	},
	// 뮤직라이브러리
	musiclibraryObj: {
		shop: '바로 구매',
		song: '반주 수령',
		claim: '트랙 수령'
	},
	haveexpired: '기한 초과',
	notstart: '미시작',
	underway: '진행중',
	songcollection: '노래공모',
	discountsection: '할인코너',
	messagecenter: '알림',
	musiclibrary: '뮤직라이브러리',
	musicAI: 'AI 작곡',
	mymusicAI: '내 작품',
	musicaigequmiaoshu: '노래 설명',
	musicaigequmiaoshutt: '원하는 음악 스타일과 주제(예: \'휴일에 대하여\')를 설명합니다.특정 아티스트나 노래가 아닌 장르와 분위기를 사용합니다.',
	musicaigequgeci: '무작위로 가사를 생성하여 직접 쓰거나 Al에게 도움을 받습니다.두 편의 시(8행)를 사용하여 최상의 결과를 얻습니다.',
	musicaigequfenggei: '팝, 록, 서사시 등 원하는 음악 스타일을 묘사합니다.저희 모델은 구체적인 아티스트 이름은 인식하지 못하지만 음악 장르와 분위기는 이해합니다.',
	musicaigequtitle: '노래의 이름을 지정합니다.',
	musicaigequgegeci: '가사',
	musicaigequgemakegeci: '가사 생성',
	musicaigequstyle: '음악 스타일',
	musicaigequgett: '노래 제목',
	musicaigequgeciinput: '가사 입력',
	musicaigequstyleinput: '음악 스타일 입력(영어로 부탁드립니다)',
	musicaigequgecittinput: '음악 이름 입력',
	musicaigequinput: '사랑에 관한 일본 팝송을 쓰십시오.',
	musicaichunyinyue: '퓨어 뮤직',
	musicaichunyinyuett: '가사가 없는 순수한 음악',
	musicaibuttonchuangzuo: '시작',
	musicaimiaoshu: '묘사',
	musicailistkong: '현재 작품이 없습니다.',
	musicaizuopin: '작품',
	musicaixiazaiyinyue: '다운로드',
	musicaichakangeci: '가사',
	musicaiwodejifen: '마이 포인트',
	musicaixiaohaojifen: '창작당 10포인트 소모',
	activityarea: '이번트파트',
	songcustomization: '맞춤제작',
	finishedmusic: '완곡 작품',
	searchprompt: '제목 혹은 번호로 검색',
	workupload: '작품업로드',
	overseas: '해외',
	hinterland: '대륙',
	songName: '노래제목',
	producer: '프로듀서',
	singer: '가수',
	Style: '장르',
	instruments: '악기',
	speed: '속도',
	filtrate: '선별',
	writWords: '작사',
	compose: '작사/작곡/편곡',
	arrangement: '작사/작곡',
	arrangementbeat: '작곡',
	lyricsText: "가사",
	singSinger: '적용가수',
	pleaseSelectSuitSinger: '해당 노래에 어울리는 가수를 선택하세요',
	pleaseSelectSongStyle: '해당 노래에 맞는 장르를 선택하세요',
	pleaseSelectSongUseInstrument: '해당 노래에 사용된 악기를 선택하세요',
	pleaseSelectSongMood: '해당 노래에 맞는 무드를 선택하세요',
	songStyle: '음악장르',
	musicalInstrument: '악기',
	claimwork: '나의 수령 제품',
	audiofile: '오디오파일',
	uploadlyrics: '작사 업로드',
	collaborate: '협업 여부',
	applylyrics: '작사작업신청',
	guidesing: '가이드작업신청',
	emotion: '무드',
	readAndAgree: '읽고 동의함',
	musicianOccupancyAgreement: '뮤지션 입주계약',
	clickUpload: '업로드하기',
	publishWork: '작품업로드',
	uploadTip: 'jpg/png형식，196*196px，파일크기 최대10M까지 가능',
	vCodeLoginText: '문자 로그인',
	passwordLoginText: '비밀번호 로그인',
	mobile: '휴대폰 번호',
	inputmobile: '휴대폰 번호를 입력하세요',
	placeholder: '입력하세요',
	vCodeText: '인증코드',
	forgotPassword: '비밀번호 찾기',
	signUpNow: '회원가입',
	testGetCode: '코드받기',
	tryAgainInSeconds: '초후 다시 시도하세요',
	email: '메일',
	password: '비밀번호',
	login: '로그인',
	userName: '아이디',
	invitationCode: '요청코드',
	register: '회원가입',
	agree: '동의',
	userAgreement: '《이용약관》',
	privacyPolicy: '《개인정보 처리방침》',
	confirm: '확인',
	alreadyHaveAnAccountToRegister: '바로 로그인',
	thePasswordEnteredTwiceDoesNotMatch: '입력하신 비밀번호가 일치하지 않습니다!',
	theMailboxFormatIsIncorrect: '잘못된 메일주소 입니다',
	agreeToTheAgreement: '협의서를 읽고 동의하신 후 로그인하시기 바랍니다.!',
	Collaboration: {
		toobig: "파일이 너무 큽니다",
		rightAr: "정확한 형식의 AR파일을 업로드하세요",
		rightmr: "정확한 형식의 MR파일을 업로드하세요",
		rightmulti: "정확한 형식의 Multi파일을 업로드하세요",
		rightAudio: "정확한 형식의 Audio파일을 업로드하세요",
		rightLyrics: "정확한 형식의 가사파일을 업로드하세요",
		rightcover: "정확한 형식의 타이틀 사진을 업로드하세요",
		shangjiaxuzhi: "업로드 고지사항",
		xuzhi1: "업로드 작품은 미 발매된 본인의 오리지널 작품임을 보증하셔야 합니다；",
		xuzhi2: "작품 심사는 기간은 72시간 입니다；",
		xuzhi3: "심사 통과된 작품을 등록 해지처리 하시려면 7일전에 등록 해지 신청을 하셔야 합니다；",
		xuzhi4: "심시기간동안 작품거래가 발생한 경우 플랫폼의 진행상황을 기준으로 합니다.",
		confirm: "확인",
		upsuccess_title: "업로드 성공",
		upsuccess_p1: "작품 업로드성공, 관리자 심사를 기다리세요！",
		upsuccess_p2: "72시간 안으로 심사를 끝낼 예정이니 잠시만 기다려 주시기바랍니다.",
		collaborationsuccuess: "제작 요청 신청 성공",
	},
	Demosea: {
		receivedSuccessfullyWhetherToDownload: "수령완성，파일을 다운 받으시겠습니까?",
		tip: "힌트",
		cancel: "캔슬",
	},
	UploadOpus: {
		noRegisteredMusician: '뮤지션인증을 하지 않은 상태로 업로드 하실수 없습니다',
		twotepCertType1: "주민등록증",
		twotepCertType2: "여권",
		twotepCertType3: "홍콩/마카오 통행증",
		twotepCertType4: "대만주민 대륙통행증",
		twotepCertType5: "주민 호구부",
	},
	//마이 페이지
	mywallet: '내 지갑',
	myworks: '내 작품',
	myinterest: '나의 팔로우',
	myfavorite: '좋아요',
	myclaim: '수령기록',
	mypurchase: '구매기록',
	mycustom: '나의 제작',
	AccountKeeper: '계좌관리',
	joinorganization: '팀 가입',
	businessmanagement: '기업관리',
	logout: '로그아웃',
	teammanagement: '팀 관리',
	accountbalance: '계좌 잔액',
	bindbankcard: '은행카드 연동',
	Listenpermission: '리스닝 권한',
	mycoupon: '나의 할인권',
	grossearnings: '총 수입',
	Todayincome: '오늘 수입',
	Todayexpend: '오늘 지출',
	cashwithdrawalamount: '현금 인출금액',
	revenuedetail: '수입/지출내역',
	requestwithdrawal: '현금 인출신청',
	havebought: '이미 구입한 곡',
	reserved: '홀드중인 곡',
	all: '전부',
	havepaid: '결제 완성',
	nonpayment: '미결제',
	authorize_title: '라이센스 정보',
	authorize_p1: "본인（이름）",
	authorize_p2: "주민등록증번호",
	authorize_p3: "（이하：“수권자”）본 허가서 부록에 기재된 녹음제품의 작·작곡자, 공연자, 녹음제작자 저작권(공연자권 포함) 및 인접권의 권리자이며, 이에 따라 xxx사 및 그 계열사(이하 '수권자'라 한다)가 중국 대륙 내 인터넷 및 전기통신망 사업자가 제공하는 채널과 플랫폼에서 수권자(xxx)가 향유하는 저작권 및 인접권의 수권 작품을 독점적으로 대리 및 운영할 수 있는 권한을 부여한다.",
	authorize_p4: "라이센스 범위",
	authorize_p5: "1，인터넷 사업(작품의 온라인 브라우징, 재생, 다운로드 등을 포함하되 이에 국한되지 않음) 및 부가통신 사업(개인화 링백톤, 링백톤, IVR, WAP, 문자메시지, 컬러레터링, 전곡 다운로드, 휴대폰 동영상, 휴대폰 내장 등 포함)에서 독점적으로 개발 및 운영하여 인터넷 사업 및 부가통신 사업 및 제3자에게 개발 및 운영 권한을 양도하여 인터넷 사업 및 부가통신 사업에 사용한다.2. 권한 있는 사람(xx)의 허가를 기반으로 한 저작물을 복제, 수정, 각색, 번역 및 기타 가공 및 제작하고, 생산된 제품은 타인에게 할당, 발행, 임대 및 정보 네트워크를 통해 대중에게 배포됩니다.3. 권한대행자(xx)는 저작물의 저작권이나 인접권 등의 권익이 침해된 경우 침해업체 또는 개인에게 책임을 묻고 소송할 것이다。",
	authorize_p6: "xx회사 및 그 관계회사는 합작작품의 보급을 목적으로 하는 경우에는 자체 또는 권한을 위임받은 파트너(xx)가 제공한 관련 이미지 및 작품을 다음 채널에서 사용할 수 있다.",
	authorize_p7: "포함하되 이에 한하지 않다",
	authorize_p8: "평면, TV 및 방송매체, 휴대전화 단말기, 영상물 등을 통한 보급.",
	authorize_p9: "FLASFH, 뮤직비디오, 동영상 등으로 홍보.",
	authorize_p10: "CD, DVD, 카세트테이프 등의 캐리어를 통해 앨범표면 인쇄나 홍보용 시트 등으로 홍보.",
	authorize_p11: "온라인 광고, 미디어 광고, 프로모션에서 라이선스 콘텐츠 이용(권한자가 제공한 사진, 뮤직비디오 등 포함).",
	authorize_p12: "허가인이 제공하는 초상 이미지 및 관련 자료 제작 홍보페이지, 카드 등 홍보물을 발급한다",
	authorize_p13: "라이센스 기간 : xx년 x월 xx일부터 xx년 x월 xx일까지.권한 만료 30일 전에 쌍방은 본 권한의 갱신 또는 변경에 관한 협의를 진행할 것이다.",
	authorize_p14: "이에 특별히 권한을 부여합니다.",
	authorize_p15: "수권자",
	unfinished: '미완성',
	completed: '완성',
	canceled: '캔슬성공',
	deadline: '요청곡 마감시간',
	issuedate: '곡 발행시간',
	customcoding: '제작코드',
	submitwork: '작품제출',
	generateinvitationcode: '요청코드생성',
	exclusiveinvitationcode: '전용요청코드',
	copyinvitationcode: '초청코드 복사',
	invitationcodevalidityperiod: '요청코드의 유효기간은 14일이며 코드당 1번만 사용가능',
	personaldetails: '개인정보',
	realname: '실명인증',
	enterprise: '기업인증',
	accountsecurity: '계정안전',
	stagename: '예명(크레딧)',
	sex: '성별',
	personalprofile: '프로필',
	finished: '완성',
	enterpersonalprofile: '개인소개를 입력하세요',
	enterstagename: '예명(크레딧)을 입력하세요',
	selectgender: "성별을 선택하세요",
	inputbriefintroduction: "프로필 입력",
	man: '남',
	woman: '여',
	enterrealname: '본인 이름을 입력하세요',
	regions: '국가지역 ',
	enterregions: '해당 국가와 지역을 선택하세요',
	documentnum: '증거서류 번호',
	enterdocumentnum: '증거서류 번호를 입력하세요',
	documentphoto: '증거서류 사진',
	enterdocumentphoto: '주민등록증/여권(정면)/주민등록증/여권（정면）',
	submitreport: '심사제출',
	businessname: '기업 타이틀',
	businesscontent: '중국대륙지역의 기업만 인증 가능합니다',
	businesslogo: '기업logo',
	limits: 'jpg/png형식，196*196px，파일크기 최대10M까지 가능',
	businesslicense: '사업자등록증',
	emailaccounts: '메일주소',
	emailaccountstitle: '사용가능한 메일주소입니다',
	telaccounts: '휴대폰 번호',
	telaccountstitle: '사용가능한 번호입니다',
	loginpassword: '비밀번호',
	loginpasswordtitle: '비밀번호는 최소한 영어문자, 기호, 숫자 중 두 개 이상을 포함해야 하며, 8자리 이상이여야 한다. 비밀번호를 자주 수정하여 계정의 안전을 보장하도록 하세요.',
	paypassword: '결제 비밀번호',
	paypasswordtitle: '비밀번호는 6자리숫자로, 비밀번호를 자주 수정하여 계정의 안전을 보장하도록 하세요',
	setting: '설정',
	revision: '수정',
	changes: '변경',
	Lognow: '바로 로그인',
	changeemail: '메일변경',
	newemail: '새로운 메일 ',
	emailtitle: '새로운 메일주소를 입력하세요',
	authcode: '인증코드',
	haveauthcode: '인증코드 받기',
	authcodetitle: '문자로 받은 인증코드를 입력하세요',
	changemobile: '휴대폰번호 변경',
	newmobile: '새로운 휴대폰 번호',
	newmobiletitle: '새로운 휴대폰 번호를 입력하세요',
	changeloginpassword: '비밀번호 변경',
	oldpassword: '사용중비밀 번호',
	oldpasswordtitle: '사용중 비밀번호를 입력하세요',
	newpassword: '새로운 비밀번호',
	newpasswordtitle: '새로운 비밀번호를 입력하세요',
	subpassword: '비밀번호 확인',
	setloginpassword: '비밀번호 설정',
	enterpaymentpassword: '결제 비밀번호 입력',
	subpaymentpassword: '결제 비밀번호를 확인하세요',
	setpaypassword: '결제 비밀번호 설정',
	nowpaypassword: '사용중 결제 비밀번호',
	newpaypassword: '새로운 결제 비밀번호',
	subpaypassword: '결제 비밀번호 확인',
	incomedetail: '수입/지출내역',
	accounttitle: '계좌이름',
	variationquantity: '변화수',
	rechargechannel: '충전방식',
	time: '시간',
	recharge: '충전',
	rechargemoney: '충전금액',
	inputrechargemoney: '원하시는 충전금액을 입력하세요',
	withdraw: '현금인출',
	changebankcard: '은행카드를  선택하세요',
	withdrawtitle: '원하시는 인출 금액을 입력하세요',
	withdrawalamount: '인출 가능 현금 금액',
	myintegral: '포인트',
	mydeintegral: '마일리지 잔액',
	exchangeintegral: '컨버션 포인트',
	exchangemoney: '환전 금액',
	exchangemoneynono: '환전 금액을 입력하십시오',
	integralDetail: "적분 내역",
	integraltypetxt: '방식',
	integralshengyutxt: '잉여 적분',
	paypaljine: '구매 금액',
	paypalzengsongjifen: '보너스 포인트 증정',
	integralshijiantxt: '시간',
	rechargeshengyu: '잔여 금액',
	bankcard: '은행카드',
	bankcardnum: '은행카드 번호',
	inputbankcardnum: '은행카드 번호를 입력하세요',
	close: '닫기',
	success: '성공',
	bindsuccess: "연동완성",
	withdrawalamountnonull: '인출 할 현금금액을 비워두지 마세요',
	removebind: '연동 해제',
	name: '이름',
	inputname: '이름을 입력하세요',
	IDnum: '주민등록증번호',
	inputIDnum: '주민등록증번호를 입력하세요',
	inputverificationcode: '문자로 보내드린 인증코드를 입력하세요',
	verificationcode: '인증코드',
	topupdetails: '충전내역',
	listeningcard: '리그닝 권한 카드',
	expire: '만기',
	timesrenewal: '남아있는 연장 횟수는 ',
	frequency: '횟수',
	Listeningprivilege: '리스닝 특권',
	srenewal: '연장',
	Renewaltime: '연장기간',
	Renewaltitle: '*리스닝 기한내 플랫폼의 모든 노래를 들으실수 있습니다.',
	notlogin: "로그인이 안된 상태입니다",
	loginmore: "로그인후 사용가능",
	promptlylogin: "바로 로그인",
	mannedplatform: "플랫폼 입주",
	uploadingworks: "작품 업로드",
	workspurchase: "작품구입",
	numberofrenewals: "남아있는 연장 횟수는",
	applyfor: "회,횟수 사용이 끝나면 재신청이 필요합니다",
	discountcoupon: "할인권",
	full: "만",
	usable: "사용가능",
	yuan: "위안",
	touse: "사용하러 가기",
	haveused: "사용",
	unverified: "미인증",
	authenticated: "인증",
	WeChatAccount: "위쳇",
	weibo: "웨이보",
	aboutus: "회사소개",
	useragreement: "이용약관",
	privacypolicy: "개인정보 처리방침",
	contactus: "연락처",
	highquality: "글로벌 고퀄리티 오리지널 데모 라이브러리",
	dark: "다크주제",
	bright: "밝은주제",
	ICP: "ICP허가증: 랴오ICP비17002816",
	claimedwork: "수령한 작품을 선택하세요",
	songname:"노래 제목을 입력하세요",
	collaboratenot:"협업여부",
	fillword: "작사 작업",
	songword: "가사",
	seesongword: "가사보기",
	replaceword: "가사변경",
	nextword: "다음가사",
	Musiccertification: "뮤지션인증",
	artname: "예명/크레딧",
	songartname: "곡 발행시 사용할 크레딧",
	isrealname: "본인이름",
	isrealnametitle: "본인 이름을 입력하시고 심사통과후 변경불가합니다",
	country: "국적",
	selectcountry: "국적을 선택하세요",
	mobiletitle: "세븐키에 제공하실 연락처",
	desc: "개인소개",
	deschome: "개인 페이지에 전시될 것입니다",
	Uploadsongtitle: "업로드하신 노래 제목 ",
	workType: "작품장르",
	seademo: "DEMO(해외)",
	songspeed: "정확한 노래 속도를 입력하세요",
	songtone: "정확한 노래 키를 입력하세요",
	cover: "표지화면",
	moremusician: "더 많은 뮤지션",
	moreMusic: "더 많은 노래",
	Accompanimentclaim: "반주 수령 성공, 제한 기간안으로 작품 업로드 하세요",
	Unabletoupload: "작품 업로드 불가시 제때에 수령을 취소하세요",
	nomore: "이하 내용없음",
	Lowerarrangement: "트랙 다운로드",
	user: "사용자",
	isenterprise: "기업",
	allpreferential: "전부 할인 내역",
	totalcommodityprice: "상품총액",
	coupondeduction: "할인권 할인",
	locksonggold: "홀드한 곡에 대한 공제금",
	Generalpreference: "총 할인",
	total: "합계",
	preferentialdetails: "할인내역",
	price: "가격",
	Uploadcomposition: "탑라인 업로드",
	audited: "심사완료",
	reviewed: "심사중",
	modified: "수정중",
	nopass: "미통과",
	Soldout: "판매된 제품입니다",
	Lockcountdown: "홀드시간 카운트다운",
	buytype: "구매 유형",
	authorizedarea: "라이센스 지역",
	authorizedtype: "라이센스 유형",
	Disbandteam: "팀 해체",
	Handoverteam: "팀 인수인계",
	Outteam: "팀에서 제명",
	teammembers: "팀 멤버",
	remove: "판매된 곡 입니다",
	registerEmail: {
		emailinput: '메일 주소를 입력하세요',
		he: '과/와'
	},
	cancelClaim: "수령 캔슬",
	uploadtext: "다운로드",
	namecomponent: "명칭：",
	tranfercomponentinfo: "티지엔터테인먼트",
	bankaccountinfo: "건설은행선양난징난지에지점",
	pleaseclaimbeat: "먼저 트랙을 수려하세요",
	orderhascreate: "주문이 생성되었습니다.송금후 증빙을 업로드해주세요!",
	paysuccess: "결제 성공",
	addsuccess: "추가 성공",
	audioplayfaild: "음원 불러오기 실패, 재생 불가",
	playhistory: "재생 기록",
	confirmDel: "삭제 화인",
	coupponnotuse: "할인권 사용불가",
	paymethodtext: "결제방식",
	submitsuccesstext: "제출완성",
	finishrecommand: "뮤지션을 배치하여 빠른 시간안으로 작품완성을 도와드리겠습니다",
	nopermissionbrowse: "Oh~~해당 페이지를 이용하실 권한이 없습니다~",
	pageislost: "무효한 링크입니다!  다른 페이지로 이동해 보세요~",
	theservercrashed: "힝.. 서버가 다운되었습니다…",
	backhome: "메인으로가기",
	backpreviouspage: "이전 페이지로",
	countryChina: "중국",
	nonextlyrics: "다음 가사 없음",
	realnameauthsucceeded: "실명인증이 성공되었습니다",
	certifiedauthsucceeded: "기업인증이 성공되었습니다",
	collectionworks: "작품 좋아요",
	managingmembers: "팀원 관리",
	inviteteammembers: "팀원 초청",
	optiondelforever: "주문이 영구삭제 되는데 계속하시겠습니까?",
	renewalsucceeded: "연장 충전성공",
	renewalfaild: "연장 충전실패",
	createteam: "바로 팀 만들기",
	teamname: "팀명",
	teamnamesuit: "팀 이름을 입력하세요",
	creater: "설립자",
	createtext: "설립",
	minsix: " 6에서 20자",
	minthree: "3에서 5자",
	createteamtabs: "팀 만들기",
	reviewworks: "작품심사",
	phoneregister: "휴대폰번호로 회원가입",
	emailregister: "이메일로 회원가입",
	resetpassword: "비밀번호 다시 만들기",
	inputactivename: "이벤트 이름을 입력하세요",
	selectactivearea: "이벤트 구역을 선택허세요",
	selectdata: "날자를 선택하세요",
	pleaseselecttime: "시간을 선택하세요",
	atleastselectactive: "이벤트 종류를 선택하세요",
	selectactivereasource: "이벤트 경로를 입력하세요",
	inputactivemethods: "이벤트 형식을 입력하세요",
	limitselection: "1개 파일만 선택가능합니다. 선택한 파일수",
	Selected: "개 파일，총 선택수",
	filenum: "개 파일",
	creatornotnull: "프로듀서 내용을 비워두지 마세요",
	selectclaimwork: "수령된 작품을 선택하세요",
	audionotnull: "오디오 파일을 업로드하세요",
	lyricsnotnull: "가사파일을 업로드하세요",
	demoupnotifytitle:"에로",
	supportmp3: "mp3파일，크기100M까지 가능",
	supportpng: "jpg/png형식,크기는 10M까지 가능",
	uploadAr: "AR파일로 업로드 하세요",
	uploadfomat: "프로필 사진은 JPG형식만 가능합니!",
	uploadsize: "프로필 사진은 2MB까지만 가능합니다!",
	uploadheadimgformat: "프로필사진 형식이 잘 못됬습니다",
	authenticationrightcertimg:"정확한 형식의 사진을 업로드하세요",
	checkmusicianagreement: "뮤지션입주 계약을 확인하세요",
	mylove: "좋아요",
	mypurchases: "구매기록",
	mycustomize: "나의 제작",
	myaccount: "계좌관리",
	insAccount: "인스타그램 계정",
	kakaotalkID: "카톡 ID",
	headImg: '프로필 사진',
	enterpriseAgreement: "기업 회원가입 이용약관",
	enterpriseTitleNotNull: "기업 이름을 적어주세요",
	businesslicenseNotNull: "사업자 등록증을 업로드하세요",
	enterpriseLogoNotNull: "기업 로고를 업로드 하세요",
	pleaseCheckEnterpriseAgree: "기업 이용약관을 확인하세요",
	readmusicianagreement: "읽고 동의 하세요《뮤지션 입주 약관》",
	revenueDetail: "수입 명세",
	revenueMethods: "수입 경로",
	kamiinputplace: '충전된 카드를 입력하십시오',
	amichongzhi: '캐시 충전',
}
export default ko