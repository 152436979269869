import Cookie from "js-cookie";
import {getVCode} from "@/api/Common";
import {Message} from 'element-ui'

// 判断浏览器函数
export const isMobile = (() => {
	if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
		return true; // 移动端
	} else {
		return false; // PC端
	}
})

// 设置token
export const setToken = ((token, expires = 1) => {
	Cookie.set('7key_token', token, {expires})
})

// 获取token
export const getToken = (() => {
	return Cookie.get('7key_token')
})

// 删除token
export const removeToken = ((name) => {
	return Cookie.remove(name)
})

// 获取主题
export const getTheme = (() => {
	return localStorage.getItem("theme");
})

// 获取语言
export const getLang = (() => {
	return localStorage.getItem("lang");
})

// 获取验证码
export const sendVCode = ((telephoneCode, mobile) => {
	getVCode({
		telephone_code: telephoneCode,
		mobile: mobile
	}).then(res => {
		console.log(res)
		if (res.status == 1) {
			Message.success(res.msg)
		}
	}).catch(err => {
		console.log('获取验证码失败:', err)
	})
})