import request from './request';

export default {
  get: function (url, params = {}) {
    return new Promise((resolve, reject) => {
      request.get(url, {
        params: params
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        })
    })
  },
  post: function (url, params = {}) {
    return new Promise((resolve, reject) => {
      request.post(url, params)
        .then(res => {
          resolve(res.data)
        }, err => {
          reject(err)
        })
    })
  },
  patch: function (url, params = {}) {
    return new Promise((resolve, reject) => {
      request.patch(url, {
        params: params
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        })
    })
  },
  delete: function (url, params = {}) {
    return new Promise((resolve, reject) => {
      request.delete(url, {
        params: params
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        })
    })
  },
  put: function (url, params = {}) {
    return new Promise((resolve, reject) => {
      request.put(url, {
        params: params
      })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        })
    })
  }
}
