export default [{
    path: '/',
    name: 'Index',
    redirect: '/recommend',
    component: () => import('@/views/Index'),
    children: [
        // 音乐库
        {
            path: 'musicLibrary',
            name: 'MusicLibrary',
            meta: {
                headerSelectName: '/musicLibrary'
            },
            redirect: '/musicLibrary/producthmusic',
            component: () => import('@/views/MusicLibrary/MusicLibrary'),
            children: [
                //成品音乐
                {
                    path: 'producthmusic',
                    name: 'ProductMusic',
                    meta: {
                        headerSelectName: '/musicLibrary'
                    },
                    component: () => import('@/views/MusicLibrary/ProductMusic')
                },
                //海外demo
                {
                    path: 'seademo',
                    name: 'SeaDemo',
                    meta: {
                        keepAlive: false,
                        headerSelectName: '/musicLibrary'
                    },
                    component: () => import('@/views/MusicLibrary/SeaDemo')
                },
                // 内地demo
                {
                    path: 'inlanddemo',
                    name: 'InlandDemo',
                    meta: {
                        headerSelectName: '/musicLibrary'
                    },
                    component: () => import('@/views/MusicLibrary/InlandDemo')
                },
                // 作品协助区
                {
                    path: 'workcollaboration',
                    name: 'Workcollaboration',
                    meta: {
                        headerSelectName: '/musicLibrary'
                    },
                    component: () => import('@/views/MusicLibrary/Workcollaboration')
                },
                // BGM
                {
                    path: 'bgm',
                    name: 'BGM',
                    meta: {
                        headerSelectName: '/musicLibrary'
                    },
                    component: () => import('@/views/MusicLibrary/BGM')
                },
                // //打开新的标签页面
                // {
                // 	path: 'newlabel',
                // 	name: 'NewLabel',
                // 	component: () => import('@/views/MusicLibrary/NewLabel')
                // },
            ]
        },
        // 推荐
        {
            path: 'recommend',
            name: 'Recommend',
            meta: {
                headerSelectName: '/recommend'
            },
            component: () => import('@/views/Recommend/Recommend.vue'),
            // children: [
            // 	{
            // 	    path: 'musicianList',
            // 	    name: 'MusicianList',
            // 	    component: () => import('@/views/Recommend/MusicianList.vue')
            // 	},
            // ]
        },
        {
            path: 'recommend/musicianList',
            name: 'MusicianList',
            component: () => import('@/views/Recommend/MusicianList.vue')
        },
        {
            path: 'recommend/teamLabel',
            name: 'TeamLabel',
            redirect: '/teamLabelFinished',
            component: () => import('@/views/Recommend/TeamLabel.vue'),
            children: [{
                path: '/teamLabelFinished',
                name: 'TeamLabelFinished',
                component: () => import('@/views/Recommend/MusicianPersonalCenterFinished.vue'),
                children: []
            },
                {
                    path: '/teamLabelFinishedDemoSea',
                    name: 'TeamLabelFinishedDemoSea',
                    component: () => import('@/views/Recommend/MusicianPersonalCenterDemoSea.vue'),
                    children: []
                },
                {
                    path: '/teamLabelFinishedDemoLocal',
                    name: 'TeamLabelFinishedDemoLocal',
                    component: () => import('@/views/Recommend/MusicianPersonalCenterDemoLocal.vue'),
                    children: []
                },
            ]
        },
        // 推荐 音乐人 个人主页
        {
            path: 'musicianList/musicianPersonalCenter',
            name: 'MusicianPersonalCenter',
            redirect: '/MusicianPersonalCenterFinished',
            component: () => import('@/views/Recommend/MusicianPersonalCenter.vue'),
            children: [{
                path: '/MusicianPersonalCenterFinished',
                name: 'MusicianPersonalCenterFinished',
                component: () => import('@/views/Recommend/MusicianPersonalCenterFinished.vue'),
                children: []
            },
                {
                    path: '/MusicianPersonalCenterDemoSea',
                    name: 'MusicianPersonalCenterDemoSea',
                    component: () => import('@/views/Recommend/MusicianPersonalCenterDemoSea.vue'),
                    children: []
                },
                {
                    path: '/MusicianPersonalCenterDemoLocal',
                    name: 'MusicianPersonalCenterDemoLocal',
                    component: () => import('@/views/Recommend/MusicianPersonalCenterDemoLocal.vue'),
                    children: []
                },
            ]
        },
        // 活动专区
        {
            path: 'activity',
            name: 'Activity',
            meta: {
                headerSelectName: '/activity'
            },
            component: () => import('@/views/Activity/Activity.vue'),
            redirect: '/activity/reference',
            children: [
                //Reference
                {
                    path: 'reference',
                    name: 'Reference',
                    meta: {
                        headerSelectName: '/activity'
                    },
                    component: () => import('@/views/Activity/Reference')
                },
                //歌曲征集
                {
                    path: 'songcollect',
                    name: 'SongCollect',
                    meta: {
                        headerSelectName: '/activity'
                    },
                    component: () => import('@/views/Activity/SongCollect')
                },
                //歌曲征集详情页
                {
                    path: 'songdetail',
                    name: 'SongDetail',
                    meta: {
                        headerSelectName: '/activity'
                    },
                    component: () => import('@/views/Activity/SongDetail')
                },
                // 折扣专区
                {
                    path: 'discountzone',
                    name: 'DiscountZone',
                    meta: {
                        headerSelectName: '/activity'
                    },
                    component: () => import('@/views/Activity/DiscountZone')
                },

            ]
        },
        // 定制
        {
            path: 'customization',
            name: 'Customization',
            meta: {
                headerSelectName: '/customization'
            },
            component: () => import('@/views/Customization/Customization.vue')
        },
        // 个人中心
        {
            path: 'personalCenter',
            name: 'PersonalCenter',
            meta: {
                isAuth: true
            },
            component: () => import('@/views/PersonalCenter/PersonalCenter.vue'),
            redirect: '/personalCenter/myWallet',
            children: [
                // 认证信息
                {
                    path: 'authenticationinfo',
                    name: 'AuthenticationInfo',
                    redirect: '/personalCenter/authenticationinfo/authenticationinfo1',
                    component: () => import('@/views/PersonalCenter/AuthenticationInfo/AuthenticationInfo'),
                    children: [
                        {
                            path: 'authenticationinfo1',
                            name: 'AuthenticationInfo1',
                            component: () => import('@/views/PersonalCenter/AuthenticationInfo/AuthenticationInfo1')
                        }
                    ]
                },
                // 我的钱包
                {
                    path: 'myWallet',
                    name: 'MyWallet',
                    redirect: '/personalCenter/myWallet/accountBalance',
                    component: () => import('@/views/PersonalCenter/MyWallet/MyWallet'),
                    children: [
                        // // 总收入
                        // {
                        // 	path: 'totalRevenue',
                        // 	name: 'TotalRevenue',
                        // 	component: () => import('@/views/PersonalCenter/MyWallet/TotalRevenue')
                        // },
                        // 账户余额
                        {
                            path: 'accountBalance',
                            name: 'AccountBalance',
                            component: () => import('@/views/PersonalCenter/MyWallet/AccountBalance')
                        },
                        {
                            path: 'integral',
                            name: 'Integral',
                            component: () => import('@/views/PersonalCenter/MyWallet/Integral')
                        },
                        // 绑定的银行卡
                        {
                            path: 'bindBank',
                            name: 'BindBank',
                            component: () => import('@/views/PersonalCenter/MyWallet/BindBank')
                        },
                        // 试听权限
                        {
                            path: 'auditionAuthority',
                            name: 'AuditionAuthority',
                            component: () => import('@/views/PersonalCenter/MyWallet/AuditionAuthority')
                        },
                        // 我的优惠卷
                        {
                            path: 'MyCoupon',
                            name: 'MyCoupon',
                            component: () => import('@/views/PersonalCenter/MyWallet/MyCoupon')
                        },
                    ]
                },
                // 我的作品
                {
                    path: 'myWork',
                    name: 'MyWork',
                    component: () => import('@/views/PersonalCenter/MyWork/MyWork'),
                    redirect: '/personalCenter/myWork/workproducthmusic',
                    children: [
                        // 成品音乐
                        {
                            path: 'workproducthmusic',
                            name: 'WorkProducthMusic',
                            component: () => import('@/views/PersonalCenter/MyWork/WorkProducthMusic')
                        },
                        // DEMO(海外)
                        {
                            path: 'workseademo',
                            name: 'WorkSeaDemo',
                            component: () => import('@/views/PersonalCenter/MyWork/WorkSeaDemo')
                        },
                        // DEMO(内地)
                        {
                            path: 'workinlanddemo',
                            name: 'WorkInlandDemo',
                            component: () => import('@/views/PersonalCenter/MyWork/WorkInlandDemo')
                        },
                        // 作品协作区
                        {
                            path: 'workarea',
                            name: 'WorkArea',
                            component: () => import('@/views/PersonalCenter/MyWork/WorkArea')
                        },

                    ]
                },
                // 我的关注
                {
                    path: 'myAttention',
                    name: 'MyAttention',
                    component: () => import('@/views/PersonalCenter/MyAttention/MyAttention')
                },
                // 我的收藏
                {
                    path: 'myCollection',
                    name: 'MyCollection',
                    component: () => import('@/views/PersonalCenter/MyCollection/MyCollection'),
                    redirect: '/personalCenter/myCollection/collectMusic',
                    children: [
                        // 成品音乐
                        {
                            path: 'collectmusic',
                            name: 'CollectMusic',
                            component: () => import('@/views/PersonalCenter/MyCollection/CollectMusic')
                        },
                        // DEMO(海外)
                        {
                            path: 'collectsea',
                            name: 'CollectSea',
                            component: () => import('@/views/PersonalCenter/MyCollection/CollectSea')
                        },
                        // DEMO(内地)
                        {
                            path: 'collectinland',
                            name: 'CollectInland',
                            component: () => import('@/views/PersonalCenter/MyCollection/CollectInland')
                        },
                        // 作品协作区
                        {
                            path: 'collectarea',
                            name: 'CollectArea',
                            component: () => import('@/views/PersonalCenter/MyCollection/CollectArea')
                        },

                    ]
                },
                // 我的认领
                {
                    path: 'myClaim',
                    name: 'MyClaim',
                    component: () => import('@/views/PersonalCenter/MyClaim/MyClaim'),
                    redirect: '/personalCenter/myClaim/complete',
                    children: [
                        // 已完成
                        {
                            path: 'complete',
                            name: 'Complete',
                            component: () => import('@/views/PersonalCenter/MyClaim/Complete')
                        },
                        // 未完成
                        {
                            path: 'unfinished',
                            name: 'Unfinished',
                            component: () => import('@/views/PersonalCenter/MyClaim/Unfinished')
                        },
                    ]
                },
                // 我的购买
                {
                    path: 'myPurchase',
                    name: 'MyPurchase',
                    component: () => import('@/views/PersonalCenter/MyPurchase/MyPurchase'),
                    redirect: '/personalCenter/MyPurchase/Purchased',
                    children: [
                        // 已购买
                        {
                            path: 'purchased',
                            name: 'Purchased',
                            component: () => import('@/views/PersonalCenter/MyPurchase/Purchased')
                        },
                        // 已锁定
                        {
                            path: 'locked',
                            name: 'Locked',
                            component: () => import('@/views/PersonalCenter/MyPurchase/Locked')
                        },

                    ]

                },
                // 我的定制
                {
                    path: 'myCustomization',
                    name: 'MyCustomization',
                    component: () => import('@/views/PersonalCenter/MyCustomization/MyCustomization'),
                    redirect: '/personalCenter/MyCustomization/CustomUnfinished',
                    children: [
                        // 已完成
                        {
                            path: 'customcomplete',
                            name: 'CustomComplete',
                            component: () => import(
                                '@/views/PersonalCenter/MyCustomization/CustomComplete')
                        },
                        // 未完成
                        {
                            path: 'customunfinished',
                            name: 'CustomUnfinished',
                            component: () => import(
                                '@/views/PersonalCenter/MyCustomization/CustomUnfinished')
                        },
                        // 已取消
                        {
                            path: 'canceled',
                            name: 'Canceled',
                            component: () => import('@/views/PersonalCenter/MyCustomization/Canceled')
                        },
                    ]
                },
                // 团队管理
                {
                    path: 'teamManagement',
                    name: 'TeamManagement',
                    // meta: {
                    // 	isBoss: true
                    // },
                    component: () => import('@/views/PersonalCenter/TeamManagement/TeamManagement'),
                    redirect: '/personalCenter/teamManagement/createTeam',
                    children: [
                        // 创建团队
                        {
                            path: 'createTeam',
                            name: 'CreateTeam',
                            component: () => import('@/views/PersonalCenter/TeamManagement/CreateTeam')
                        },
                        // 创建团队--表单
                        {
                            path: 'createTeamForm',
                            name: 'CreateTeamForm',
                            component: () => import(
                                '@/views/PersonalCenter/TeamManagement/CreateTeamForm')
                        },
                        // 审核作品
                        {
                            path: 'reviewWorks',
                            name: 'ReviewWorks',
                            component: () => import('@/views/PersonalCenter/TeamManagement/ReviewWorks')
                        },
                        // 管理成员
                        {
                            path: 'managingMembers',
                            name: 'ManagingMembers',
                            component: () => import(
                                '@/views/PersonalCenter/TeamManagement/ManagingMembers')
                        },
                        // 邀请团队成员
                        {
                            path: 'inviteMembers',
                            name: 'InviteMembers',
                            component: () => import(
                                '@/views/PersonalCenter/TeamManagement/InviteMembers')
                        },
                    ]
                },
                // 企业管理
                {
                    path: 'enterpriseManagement',
                    name: 'EnterpriseManagement',
                    component: () => import(
                        '@/views/PersonalCenter/EnterpriseManagement/EnterpriseManagement'),
                    redirect: '/personalCenter/enterpriseManagement/collectWorks',
                    children: [
                        // 作品收藏
                        {
                            path: 'collectWorks',
                            name: 'CollectWorks',
                            component: () => import(
                                '@/views/PersonalCenter/EnterpriseManagement/CollectWorks')
                        },
                        // 管理成员
                        {
                            path: 'managementMember',
                            name: 'ManagementMember',
                            component: () => import(
                                '@/views/PersonalCenter/TeamManagement/ManagingMembers')
                        },
                        // 邀请团队成员
                        {
                            path: 'inviteMembers',
                            name: 'InviteMembers',
                            component: () => import(
                                '@/views/PersonalCenter/TeamManagement/InviteMembers')
                        },
                    ]
                },
                // 加入组织
                {
                    path: 'joinOrganization',
                    name: 'JoinOrganization',
                    component: () => import('@/views/PersonalCenter/JoinOrganization/JoinOrganization'),
                    redirect: '/personalCenter/joinOrganization/invitationCode',
                    children: [{
                        path: 'invitationCode',
                        name: 'InvitationCode',
                        component: () => import(
                            '@/views/PersonalCenter/JoinOrganization/InvitationCode')
                    }]
                },
                // 账户管理
                {
                    path: 'accountManagement',
                    name: 'AccountManagement',
                    component: () => import('@/views/PersonalCenter/AccountManagement/AccountManagement'),
                    redirect: '/personalCenter/accountManagement/personInformation',
                    children: [
                        // 个人资料
                        {
                            path: 'personInformation',
                            name: 'PersonInformation',
                            component: () => import(
                                '@/views/PersonalCenter/AccountManagement/PersonInformation')
                        },
                        // 实名认证
                        {
                            path: 'realnameAuthentication',
                            name: 'RealnameAuthentication',
                            component: () => import(
                                '@/views/PersonalCenter/AccountManagement/RealnameAuthentication')
                        },
                        // 实名认证 -- 成功
                        {
                            path: 'authenticationSucceeded',
                            name: 'AuthenticationSucceeded',
                            component: () => import(
                                '@/views/PersonalCenter/AccountManagement/AuthenticationSucceeded')
                        },
                        // 企业认证
                        {
                            path: 'enterpriseCertification',
                            name: 'EnterpriseCertification',
                            component: () => import(
                                '@/views/PersonalCenter/AccountManagement/EnterpriseCertification'),

                        },
                        // 企业认证 -- 成功
                        {
                            path: 'certificationSucceeded',
                            name: 'CertificationSucceeded',
                            component: () => import(
                                '@/views/PersonalCenter/AccountManagement/CertificationSucceeded')
                        },
                        // 账号安全
                        {
                            path: 'accountSecurity',
                            name: 'AccountSecurity',
                            component: () => import(
                                '@/views/PersonalCenter/AccountManagement/AccountSecurity')
                        },
                    ]
                },
            ]
        },
        // 作品协作区上传
        {
            path: 'workupload',
            name: 'Workupload',
            redirect: '/workupload/collaborationarea',
            component: () => import('@/views/UploadOpus/Workupload'),
            children: [{
                path: 'collaborationarea',
                name: 'Collaborationarea',
                component: () => import('@/views/UploadOpus/CollaborationArea')
            },
            ]
        },
        // 作品上传
        {
            path: 'uploaddemo',
            name: 'uploadDemo',
            redirect: '/uploaddemo/uploadinlanddemo',
            component: () => import('@/views/UploadOpus/UploadDemo'),
            children: [{
                path: 'uploadinlanddemo',
                name: 'uploadInlandDemo',
                component: () => import('@/views/UploadOpus/UploadInlandDemo')
            },
                {
                    path: 'uploadoutbackdemo',
                    name: 'uploadOutbackDemo',
                    component: () => import('@/views/UploadOpus/UploadOutbackDemo')
                },
            ]
        },
        // 作品上传
        {
            path: 'uploadopus',
            name: 'UploadOpus',
            redirect: '/uploadopus/finishmusic',
            component: () => import('@/views/UploadOpus/UploadOpus'),
            children: [{
                path: 'finishmusic',
                name: 'FinishMusic',
                component: () => import('@/views/UploadOpus/FinishMusic')
            },
                {
                    path: 'abroaddemo',
                    name: 'AbroadDemo',
                    component: () => import('@/views/UploadOpus/AbroadDemo')
                },
                {
                    path: 'outbackdemo',
                    name: 'OutBackDemo',
                    component: () => import('@/views/UploadOpus/OutBackDemo')
                },
                {
                    path: 'uploadbeat',
                    name: 'uploadBeat',
                    component: () => import('@/views/UploadOpus/UploadBeat')
                },
                // 音乐人认证
                {
                    path: 'musiciancertification',
                    name: 'MusicianCertification',
                    component: () => import('@/views/UploadOpus/MusicianCertification')
                },

            ]
        },

        //打开新的标签页面
        {
            path: 'newlabel',
            name: 'NewLabel',
            component: () => import('@/views/MusicLibrary/NewLabel')
        },
        // 登录
        {
            path: 'login',
            name: 'Login',
            redirect: '/login/vcodelogin',
            component: () => import('@/views/Login/Login'),
            children: [{
                path: 'vcodelogin',
                name: 'VCodeLogin',
                component: () => import('@/views/Login/VCodeLogin')
            },
                {
                    path: 'passwordlogin',
                    name: 'PasswordLogin',
                    component: () => import('@/views/Login/PasswordLogin')
                }
            ]
        },
        // 注册
        {
            path: 'register',
            name: 'Login',
            redirect: '/register/mobileregister',
            component: () => import('@/views/Register/Register'),
            children: [{
                path: 'mobileregister',
                name: 'MobileRegister',
                component: () => import('@/views/Register/MobileRegister')
            },
                {
                    path: 'emailregister',
                    name: 'EmailRegister',
                    component: () => import('@/views/Register/EmailRegister')
                }
            ]
        },
        // AI创作
        {
            path: 'musicai',
            name: 'Musicai',
            meta: {
                headerSelectName: '/musicai'
            },
            component: () => import('@/views/Musicai/Musicai.vue'),
            redirect: '/musicai/musicaimake',
            children: [
                //AI编曲
                {
                    path: 'musicaimake',
                    name: 'MusicaiMake',
                    meta: {
                        headerSelectName: '/musicai'
                    },
                    component: () => import('@/views/Musicai/MusicaiMake')
                },
                // AI编曲列表
                {
                    path: 'musicailist',
                    name: 'MusicaiList',
                    meta: {
                        headerSelectName: '/musicai'
                    },
                    component: () => import('@/views/Musicai/MusicaiList')
                },
                // AI音频转换
                {
                    path: 'Musicfy',
                    name: 'Musicfy',
                    meta: {
                        headerSelectName: '/musicai'
                    },
                    component: () => import('@/views/Musicai/Musicfy')
                },

            ]
        },
        {
            path: 'reset',
            name: 'Reset',
            redirect: '/reset/resetpassword',
            component: () => import('@/views/ResetPassword/Reset.vue'),
            children: [{
                path: 'resetpassword',
                name: 'ResetPassword',
                component: () => import('@/views/ResetPassword/ResetPassword.vue')
            },
            ]
        },
    ]
},
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue')
    },
    {
        path: '*',
        redirect: {
            name: "Error404"
        }
    },
    {
        path: '/401',
        name: 'Error401',
        meta: {},
        component: () => import('@/views/ErrorPage/401')
    },
    {
        path: '/404',
        name: 'Error404',
        meta: {},
        component: () => import('@/views/ErrorPage/404')
    },
    {
        path: '/500',
        name: 'Error500',
        meta: {},
        component: () => import('@/views/ErrorPage/500')
    },
    // {
    // 	path: '/test',
    // 	name: 'test',
    // 	meta: {},
    // 	component: () => import('@/views/test.vue')
    // },
]
