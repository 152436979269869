export const darkColor = {
    primaryColor: `#fff`,
    primaryTextColor: `#000000`,
    // baseBackgroundColor: '#1A1B32'
    baseBackgroundColor: '#141629',
    footerBackgroundColor: '#1B1D30',
    recommendMusicianListBGColor: 'rgba(255, 255, 255, 0.0800)',
    recommendMusicianListTextColor: '#FFFFFF',
    recommendMusicianListTitleColor: 'rgba(255,255,255,0.5)',
    recommendMusicianListButtontBGColor: 'rgba(255,255,255,0.3)',
    headerSelectTextColor: '#FFFFFF',
    headerTextColor: 'rgba(255,255,255,.7)',
    headerSearchBg: '#191C32',
    messageIconColor: '#FFF',
    iconListBGColor: 'rgba(255, 255, 255, 0.1500)',
    musicLibraryFilterMenuNavBGColor: '#191C32',
    musicLibraryFilterMenuNavTextColor: '#ffffff',
    musicLibraryFilterMenuNavTitleColor: '#ffffff',
    demoitemsongnameColor: '#ffffff',
    demoitemsongMusicianColor: 'rgba(255, 255, 255, 0.5)',
    demoitemtabsColor: '#FFFFFF',
    demoitemtabsBGColor: 'rgba(255,255,255,0.15)',
    demoitemBGC: 'rgba(255,255,255,0.08)',
    uploadInputBackgroundColor: '#3C3D50',
    popupBackgroundColor: '#191C32',
    popupCommonBorderColor: 'rgba(255, 255, 255, 0.3000)',
    popupCancelBgColor: 'rgba(255, 255, 255, 0.3000)',
    popupCancelTextColor: '#FFFFFF',
    popupCancelBorderColor: 'rgba(255, 255, 255, 0.3000)',
    popupLockTimeBorderColor: 'rgba(255, 255, 255, 0.0800)',
    customizationLineColor: 'rgba(255,255,255,0.15)',
    uploadFormTextColor: '#fff',
    popupgray: 'rgba(255,255,255,0.6)',
    //选中背景色
    truebuttonBgColor: 'rgba(255,255,255,0.08)',
    // 按钮选中边框颜色
    truebuttonBorderBgColor: '#FFFFFF',
    //按钮选中文字颜色
    truebuttonTextColor: '#FFFFFF',
    //正常按钮背景色
    buttonBgColor: 'rgba(255,255,255,0.08);',
    //正常按钮边框
    buttonBorderBgColor: 'rgba(255,255,255,0.08)',
    //正常按钮文字颜色
    buttonTextColor: '#FFFFFF',
    iconColorAll:'#FFFFFF',
    loginInputBgColor: 'rgba(255, 255, 255, 0.08)',
    loginTopLogoBgColor: '#121628',
    loginTopLogoBorderColor: 'rgba(255, 255, 255, .2)',
	personInfoFormColor: '#3B3D50',
	personInfoRadioBgColor: '#222426',
    lineColor:'#ffffff',
	tableHoverBgColor: '#212e3e',
	collectYellow: '#FEC002',
    inputHoverColor: '#fff',
    tagBgColor: 'rgba(255, 255, 255, 0.08)',
	songBgColor: 'rgba(255, 255, 255, 0.08)',
	iconColorSwiperBtn: '#434458',
    musicPlayerBgColor: '#262839',
	loginPopColor: 'rgba(25, 28, 50, 0.6)',
	messageLineColor: 'rgba(255, 255, 255, 0.3)',
    aigecijiazaiBackgroundColor: '#191C32',
}
